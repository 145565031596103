@import '../animate/animate';
.rotate {
  animation: rotation 2s infinite linear;
}

.fadeout{
  animation: fadeout 10s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fadeout {
  0% { opacity: 1;}
  25% { opacity: 0.25;}
  50% { opacity: 0.5;}
  75% { opacity: 0.75;}
  100% { opacity: 0;}
}

.logo-white{
  path{
    fill: #ffffff;
  }
}

.loading-wrapper{
  width: 100%;
  height: 100vh;
  background: root(--main-color);
  position: fixed;
  z-index: 99999999;
  @include flexbox(center, center);
  .loading-content{
    text-align: center;
    h1{
      color: #ffffff;
      font-size: 40px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}


.general_loading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(#fff, 0.7);

  .rotate {
    animation: rotation 2s infinite linear;
    &.logo-white{
      path{
        fill: #52A8FF !important;
      }
    }
  }
}


.groupMsg {
  max-width: 700px;
  &.success {
    max-width: 700px;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    font-size: 14px;
    padding: 10px 20px;
    font-weight: bold !important;
  }
  &.error {
    max-width: 700px;
    color: var(--red) !important;
    border: 1px solid var(--red);
    font-size: 14px;
    padding: 10px 20px;
    font-weight: bold !important;
  }
}
.msg {
  margin: 10px 0px;
  text-align: center;
  font-size: 20px;
  margin: 0 auto;
  position: fixed;
  width: 700px;
  left: 0;
  right: 0;
  top: 5px;
  z-index: 999;
  background-color: #fff;
  @include fadeInUp(
    $duration: 0.3s
  );
  &.error {
    color: $red;
  }
  &.success {
    color: green;
  }
}