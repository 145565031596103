@charset "utf-8";

@mixin query($size) {
    @media all and (max-width: $size + px) {
        @content;
    }
}

@mixin minquery($size) {
    @media all and (min-width: $size + px) {
        @content;
    }
}

@mixin minmaxquery($size, $size1) {
    @media only screen and (min-width: $size + px) and (max-width: $size1 + px) {
        @content;
    }
}

$cl: #518CED;
$cltext: #5F5F5F;
$cltitle: #5F5F5F;
$cltitlesub: #5F5F5F;
$sp: 1180;
$tab: 1180;
$pc: 1181;
$boder: #5F5F5F;
//Container Define
$container: 1240;
$container-large: 1480;
$q960: 960;
$q768: 768; 
$q520: 520;
$q414: 414;
$q320: 320;

//Preload
$preload: true; 

//image dir define.
$dir: "../images/";
$dir2: "../../images/";

//color define:
$white: #fff;
$gray: #ccc;
$black: #000;
$blue: #518CED !default;
$wblue: #52A8FF !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #FD64A5 !default;
$red: #ED5555 !default;
$orange: #F7931E !default;
$yellow: #ffc107 !default;
$green: #34C759 !default;
$green2: #51C17D !default;
$teal: #2ECC87 !default;
$cyan: #0dcaf0 !default;