@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-transition: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  -webkit-text-size-adjust: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

h1 {
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}

b, strong {
  font-weight: bold !important;
}

code, kbd, pre, samp, tt {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  position: relative;
  vertical-align: baseline;
  border: 0;
  outline: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
}

button, input {
  /* 1 */
  overflow: visible;
  -webkit-transition: all .2s ease-in-out 0s;
  transition: all .2s ease-in-out 0s;
}

button, select {
  /* 1 */
  text-transform: none;
}

input, select {
  vertical-align: middle;
}

input:focus {
  outline: 0;
}

textarea {
  overflow: auto;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

progress {
  vertical-align: baseline;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

summary {
  display: list-item;
}

template, [hidden], .hidden {
  display: none !important;
}

blockquote, q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

img {
  vertical-align: top;
  border-style: none;
  line-height: 0;
  font-size: 0;
  -ms-interpolation-mode: bicubic;
  background: 0 0;
  zoom: 1;
}

ol, ul {
  list-style: none;
}

a {
  background-color: transparent;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  background: 0 0;
}

a:focus {
  outline: 0;
}

a img {
  opacity: 1;
  -webkit-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}

a img:hover {
  opacity: 0.7;
  -webkit-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

a,
button,
input {
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
}

/* CSS Document */
/*====================================================================================
笆�01.MARGIN DEFAULT - PIXEL
====================================================================================*/
/*MARGIN*/
.m00 {
  margin: 0px !important;
}

.m01 {
  margin: 1px !important;
}

.m02 {
  margin: 2px !important;
}

.m03 {
  margin: 3px !important;
}

.m04 {
  margin: 4px !important;
}

.m05 {
  margin: 5px !important;
}

.m06 {
  margin: 6px !important;
}

.m07 {
  margin: 7px !important;
}

.m08 {
  margin: 8px !important;
}

.m09 {
  margin: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.m11 {
  margin: 11px !important;
}

.m12 {
  margin: 12px !important;
}

.m13 {
  margin: 13px !important;
}

.m14 {
  margin: 14px !important;
}

.m15 {
  margin: 15px !important;
}

.m16 {
  margin: 16px !important;
}

.m17 {
  margin: 17px !important;
}

.m18 {
  margin: 18px !important;
}

.m19 {
  margin: 19px !important;
}

.m20 {
  margin: 20px !important;
}

.m21 {
  margin: 21px !important;
}

.m22 {
  margin: 22px !important;
}

.m23 {
  margin: 23px !important;
}

.m24 {
  margin: 24px !important;
}

.m25 {
  margin: 25px !important;
}

.m26 {
  margin: 26px !important;
}

.m27 {
  margin: 27px !important;
}

.m28 {
  margin: 28px !important;
}

.m29 {
  margin: 29px !important;
}

.m30 {
  margin: 30px !important;
}

.m31 {
  margin: 31px !important;
}

.m32 {
  margin: 32px !important;
}

.m33 {
  margin: 33px !important;
}

.m34 {
  margin: 34px !important;
}

.m35 {
  margin: 35px !important;
}

.m36 {
  margin: 36px !important;
}

.m37 {
  margin: 37px !important;
}

.m38 {
  margin: 38px !important;
}

.m39 {
  margin: 39px !important;
}

.m40 {
  margin: 40px !important;
}

.m41 {
  margin: 41px !important;
}

.m42 {
  margin: 42px !important;
}

.m43 {
  margin: 43px !important;
}

.m44 {
  margin: 44px !important;
}

.m45 {
  margin: 45px !important;
}

.m46 {
  margin: 46px !important;
}

.m47 {
  margin: 47px !important;
}

.m48 {
  margin: 48px !important;
}

.m49 {
  margin: 49px !important;
}

.m50 {
  margin: 50px !important;
}

.m51 {
  margin: 51px !important;
}

.m52 {
  margin: 52px !important;
}

.m53 {
  margin: 53px !important;
}

.m54 {
  margin: 54px !important;
}

.m55 {
  margin: 55px !important;
}

.m56 {
  margin: 56px !important;
}

.m57 {
  margin: 57px !important;
}

.m58 {
  margin: 58px !important;
}

.m59 {
  margin: 59px !important;
}

.m60 {
  margin: 60px !important;
}

.m61 {
  margin: 61px !important;
}

.m62 {
  margin: 62px !important;
}

.m63 {
  margin: 63px !important;
}

.m64 {
  margin: 64px !important;
}

.m65 {
  margin: 65px !important;
}

.m66 {
  margin: 66px !important;
}

.m67 {
  margin: 67px !important;
}

.m68 {
  margin: 68px !important;
}

.m69 {
  margin: 69px !important;
}

.m70 {
  margin: 70px !important;
}

.m71 {
  margin: 71px !important;
}

.m72 {
  margin: 72px !important;
}

.m73 {
  margin: 73px !important;
}

.m74 {
  margin: 74px !important;
}

.m75 {
  margin: 75px !important;
}

.m76 {
  margin: 76px !important;
}

.m77 {
  margin: 77px !important;
}

.m78 {
  margin: 78px !important;
}

.m79 {
  margin: 79px !important;
}

.m80 {
  margin: 80px !important;
}

.m81 {
  margin: 81px !important;
}

.m82 {
  margin: 82px !important;
}

.m83 {
  margin: 83px !important;
}

.m84 {
  margin: 84px !important;
}

.m85 {
  margin: 85px !important;
}

.m86 {
  margin: 86px !important;
}

.m87 {
  margin: 87px !important;
}

.m88 {
  margin: 88px !important;
}

.m89 {
  margin: 89px !important;
}

.m90 {
  margin: 90px !important;
}

.m91 {
  margin: 91px !important;
}

.m92 {
  margin: 92px !important;
}

.m93 {
  margin: 93px !important;
}

.m94 {
  margin: 94px !important;
}

.m95 {
  margin: 95px !important;
}

.m96 {
  margin: 96px !important;
}

.m97 {
  margin: 97px !important;
}

.m98 {
  margin: 98px !important;
}

.m99 {
  margin: 99px !important;
}

.m100 {
  margin: 100px !important;
}

/*MARGIN - TOP*/
.mt00 {
  margin-top: 0px !important;
}

.mt01 {
  margin-top: 1px !important;
}

.mt02 {
  margin-top: 2px !important;
}

.mt03 {
  margin-top: 3px !important;
}

.mt04 {
  margin-top: 4px !important;
}

.mt05 {
  margin-top: 5px !important;
}

.mt06 {
  margin-top: 6px !important;
}

.mt07 {
  margin-top: 7px !important;
}

.mt08 {
  margin-top: 8px !important;
}

.mt09 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt11 {
  margin-top: 11px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mt13 {
  margin-top: 13px !important;
}

.mt14 {
  margin-top: 14px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt17 {
  margin-top: 17px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.mt19 {
  margin-top: 19px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt21 {
  margin-top: 21px !important;
}

.mt22 {
  margin-top: 22px !important;
}

.mt23 {
  margin-top: 23px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt26 {
  margin-top: 26px !important;
}

.mt27 {
  margin-top: 27px !important;
}

.mt28 {
  margin-top: 28px !important;
}

.mt29 {
  margin-top: 29px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt31 {
  margin-top: 31px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mt33 {
  margin-top: 33px !important;
}

.mt34 {
  margin-top: 34px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.mt37 {
  margin-top: 37px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.mt39 {
  margin-top: 39px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt41 {
  margin-top: 41px !important;
}

.mt42 {
  margin-top: 42px !important;
}

.mt43 {
  margin-top: 43px !important;
}

.mt44 {
  margin-top: 44px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt46 {
  margin-top: 46px !important;
}

.mt47 {
  margin-top: 47px !important;
}

.mt48 {
  margin-top: 48px !important;
}

.mt49 {
  margin-top: 49px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt51 {
  margin-top: 51px !important;
}

.mt52 {
  margin-top: 52px !important;
}

.mt53 {
  margin-top: 53px !important;
}

.mt54 {
  margin-top: 54px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt56 {
  margin-top: 56px !important;
}

.mt57 {
  margin-top: 57px !important;
}

.mt58 {
  margin-top: 58px !important;
}

.mt59 {
  margin-top: 59px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt61 {
  margin-top: 61px !important;
}

.mt62 {
  margin-top: 62px !important;
}

.mt63 {
  margin-top: 63px !important;
}

.mt64 {
  margin-top: 64px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mt66 {
  margin-top: 66px !important;
}

.mt67 {
  margin-top: 67px !important;
}

.mt68 {
  margin-top: 68px !important;
}

.mt69 {
  margin-top: 69px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt71 {
  margin-top: 71px !important;
}

.mt72 {
  margin-top: 72px !important;
}

.mt73 {
  margin-top: 73px !important;
}

.mt74 {
  margin-top: 74px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt76 {
  margin-top: 76px !important;
}

.mt77 {
  margin-top: 77px !important;
}

.mt78 {
  margin-top: 78px !important;
}

.mt79 {
  margin-top: 79px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt81 {
  margin-top: 81px !important;
}

.mt82 {
  margin-top: 82px !important;
}

.mt83 {
  margin-top: 83px !important;
}

.mt84 {
  margin-top: 84px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mt86 {
  margin-top: 86px !important;
}

.mt87 {
  margin-top: 87px !important;
}

.mt88 {
  margin-top: 88px !important;
}

.mt89 {
  margin-top: 89px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt91 {
  margin-top: 91px !important;
}

.mt92 {
  margin-top: 92px !important;
}

.mt93 {
  margin-top: 93px !important;
}

.mt94 {
  margin-top: 94px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mt96 {
  margin-top: 96px !important;
}

.mt97 {
  margin-top: 97px !important;
}

.mt98 {
  margin-top: 98px !important;
}

.mt99 {
  margin-top: 99px !important;
}

.mt100 {
  margin-top: 100px !important;
}

/*MARGIN - RIGHT*/
.mr00 {
  margin-right: 0px !important;
}

.mr01 {
  margin-right: 1px !important;
}

.mr02 {
  margin-right: 2px !important;
}

.mr03 {
  margin-right: 3px !important;
}

.mr04 {
  margin-right: 4px !important;
}

.mr05 {
  margin-right: 5px !important;
}

.mr06 {
  margin-right: 6px !important;
}

.mr07 {
  margin-right: 7px !important;
}

.mr08 {
  margin-right: 8px !important;
}

.mr09 {
  margin-right: 9px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr11 {
  margin-right: 11px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr13 {
  margin-right: 13px !important;
}

.mr14 {
  margin-right: 14px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.mr17 {
  margin-right: 17px !important;
}

.mr18 {
  margin-right: 18px !important;
}

.mr19 {
  margin-right: 19px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr21 {
  margin-right: 21px !important;
}

.mr22 {
  margin-right: 22px !important;
}

.mr23 {
  margin-right: 23px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr26 {
  margin-right: 26px !important;
}

.mr27 {
  margin-right: 27px !important;
}

.mr28 {
  margin-right: 28px !important;
}

.mr29 {
  margin-right: 29px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr31 {
  margin-right: 31px !important;
}

.mr32 {
  margin-right: 32px !important;
}

.mr33 {
  margin-right: 33px !important;
}

.mr34 {
  margin-right: 34px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr36 {
  margin-right: 36px !important;
}

.mr37 {
  margin-right: 37px !important;
}

.mr38 {
  margin-right: 38px !important;
}

.mr39 {
  margin-right: 39px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr41 {
  margin-right: 41px !important;
}

.mr42 {
  margin-right: 42px !important;
}

.mr43 {
  margin-right: 43px !important;
}

.mr44 {
  margin-right: 44px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr46 {
  margin-right: 46px !important;
}

.mr47 {
  margin-right: 47px !important;
}

.mr48 {
  margin-right: 48px !important;
}

.mr49 {
  margin-right: 49px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr51 {
  margin-right: 51px !important;
}

.mr52 {
  margin-right: 52px !important;
}

.mr53 {
  margin-right: 53px !important;
}

.mr54 {
  margin-right: 54px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr56 {
  margin-right: 56px !important;
}

.mr57 {
  margin-right: 57px !important;
}

.mr58 {
  margin-right: 58px !important;
}

.mr59 {
  margin-right: 59px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mr61 {
  margin-right: 61px !important;
}

.mr62 {
  margin-right: 62px !important;
}

.mr63 {
  margin-right: 63px !important;
}

.mr64 {
  margin-right: 64px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.mr66 {
  margin-right: 66px !important;
}

.mr67 {
  margin-right: 67px !important;
}

.mr68 {
  margin-right: 68px !important;
}

.mr69 {
  margin-right: 69px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mr71 {
  margin-right: 71px !important;
}

.mr72 {
  margin-right: 72px !important;
}

.mr73 {
  margin-right: 73px !important;
}

.mr74 {
  margin-right: 74px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.mr76 {
  margin-right: 76px !important;
}

.mr77 {
  margin-right: 77px !important;
}

.mr78 {
  margin-right: 78px !important;
}

.mr79 {
  margin-right: 79px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mr81 {
  margin-right: 81px !important;
}

.mr82 {
  margin-right: 82px !important;
}

.mr83 {
  margin-right: 83px !important;
}

.mr84 {
  margin-right: 84px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.mr86 {
  margin-right: 86px !important;
}

.mr87 {
  margin-right: 87px !important;
}

.mr88 {
  margin-right: 88px !important;
}

.mr89 {
  margin-right: 89px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mr91 {
  margin-right: 91px !important;
}

.mr92 {
  margin-right: 92px !important;
}

.mr93 {
  margin-right: 93px !important;
}

.mr94 {
  margin-right: 94px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.mr96 {
  margin-right: 96px !important;
}

.mr97 {
  margin-right: 97px !important;
}

.mr98 {
  margin-right: 98px !important;
}

.mr99 {
  margin-right: 99px !important;
}

.mr100 {
  margin-right: 100px !important;
}

/*MARGIN - BOTTOM*/
.mb00 {
  margin-bottom: 0px !important;
}

.mb01 {
  margin-bottom: 1px !important;
}

.mb02 {
  margin-bottom: 2px !important;
}

.mb03 {
  margin-bottom: 3px !important;
}

.mb04 {
  margin-bottom: 4px !important;
}

.mb05 {
  margin-bottom: 5px !important;
}

.mb06 {
  margin-bottom: 6px !important;
}

.mb07 {
  margin-bottom: 7px !important;
}

.mb08 {
  margin-bottom: 8px !important;
}

.mb09 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb11 {
  margin-bottom: 11px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mb13 {
  margin-bottom: 13px !important;
}

.mb14 {
  margin-bottom: 14px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb17 {
  margin-bottom: 17px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.mb19 {
  margin-bottom: 19px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb21 {
  margin-bottom: 21px !important;
}

.mb22 {
  margin-bottom: 22px !important;
}

.mb23 {
  margin-bottom: 23px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb26 {
  margin-bottom: 26px !important;
}

.mb27 {
  margin-bottom: 27px !important;
}

.mb28 {
  margin-bottom: 28px !important;
}

.mb29 {
  margin-bottom: 29px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb31 {
  margin-bottom: 31px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb33 {
  margin-bottom: 33px !important;
}

.mb34 {
  margin-bottom: 34px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb36 {
  margin-bottom: 36px !important;
}

.mb37 {
  margin-bottom: 37px !important;
}

.mb38 {
  margin-bottom: 38px !important;
}

.mb39 {
  margin-bottom: 39px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb41 {
  margin-bottom: 41px !important;
}

.mb42 {
  margin-bottom: 42px !important;
}

.mb43 {
  margin-bottom: 43px !important;
}

.mb44 {
  margin-bottom: 44px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb46 {
  margin-bottom: 46px !important;
}

.mb47 {
  margin-bottom: 47px !important;
}

.mb48 {
  margin-bottom: 48px !important;
}

.mb49 {
  margin-bottom: 49px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb51 {
  margin-bottom: 51px !important;
}

.mb52 {
  margin-bottom: 52px !important;
}

.mb53 {
  margin-bottom: 53px !important;
}

.mb54 {
  margin-bottom: 54px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb56 {
  margin-bottom: 56px !important;
}

.mb57 {
  margin-bottom: 57px !important;
}

.mb58 {
  margin-bottom: 58px !important;
}

.mb59 {
  margin-bottom: 59px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb61 {
  margin-bottom: 61px !important;
}

.mb62 {
  margin-bottom: 62px !important;
}

.mb63 {
  margin-bottom: 63px !important;
}

.mb64 {
  margin-bottom: 64px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb66 {
  margin-bottom: 66px !important;
}

.mb67 {
  margin-bottom: 67px !important;
}

.mb68 {
  margin-bottom: 68px !important;
}

.mb69 {
  margin-bottom: 69px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb71 {
  margin-bottom: 71px !important;
}

.mb72 {
  margin-bottom: 72px !important;
}

.mb73 {
  margin-bottom: 73px !important;
}

.mb74 {
  margin-bottom: 74px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb76 {
  margin-bottom: 76px !important;
}

.mb77 {
  margin-bottom: 77px !important;
}

.mb78 {
  margin-bottom: 78px !important;
}

.mb79 {
  margin-bottom: 79px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb81 {
  margin-bottom: 81px !important;
}

.mb82 {
  margin-bottom: 82px !important;
}

.mb83 {
  margin-bottom: 83px !important;
}

.mb84 {
  margin-bottom: 84px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb86 {
  margin-bottom: 86px !important;
}

.mb87 {
  margin-bottom: 87px !important;
}

.mb88 {
  margin-bottom: 88px !important;
}

.mb89 {
  margin-bottom: 89px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb91 {
  margin-bottom: 91px !important;
}

.mb92 {
  margin-bottom: 92px !important;
}

.mb93 {
  margin-bottom: 93px !important;
}

.mb94 {
  margin-bottom: 94px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb96 {
  margin-bottom: 96px !important;
}

.mb97 {
  margin-bottom: 97px !important;
}

.mb98 {
  margin-bottom: 98px !important;
}

.mb99 {
  margin-bottom: 99px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

/*MARGIN - LEFT*/
.ml00 {
  margin-left: 0px !important;
}

.ml01 {
  margin-left: 1px !important;
}

.ml02 {
  margin-left: 2px !important;
}

.ml03 {
  margin-left: 3px !important;
}

.ml04 {
  margin-left: 4px !important;
}

.ml05 {
  margin-left: 5px !important;
}

.ml06 {
  margin-left: 6px !important;
}

.ml07 {
  margin-left: 7px !important;
}

.ml08 {
  margin-left: 8px !important;
}

.ml09 {
  margin-left: 9px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml11 {
  margin-left: 11px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.ml13 {
  margin-left: 13px !important;
}

.ml14 {
  margin-left: 14px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml17 {
  margin-left: 17px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.ml19 {
  margin-left: 19px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml21 {
  margin-left: 21px !important;
}

.ml22 {
  margin-left: 22px !important;
}

.ml23 {
  margin-left: 23px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml26 {
  margin-left: 26px !important;
}

.ml27 {
  margin-left: 27px !important;
}

.ml28 {
  margin-left: 28px !important;
}

.ml29 {
  margin-left: 29px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml31 {
  margin-left: 31px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.ml33 {
  margin-left: 33px !important;
}

.ml34 {
  margin-left: 34px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml36 {
  margin-left: 36px !important;
}

.ml37 {
  margin-left: 37px !important;
}

.ml38 {
  margin-left: 38px !important;
}

.ml39 {
  margin-left: 39px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml41 {
  margin-left: 41px !important;
}

.ml42 {
  margin-left: 42px !important;
}

.ml43 {
  margin-left: 43px !important;
}

.ml44 {
  margin-left: 44px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml46 {
  margin-left: 46px !important;
}

.ml47 {
  margin-left: 47px !important;
}

.ml48 {
  margin-left: 48px !important;
}

.ml49 {
  margin-left: 49px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml51 {
  margin-left: 51px !important;
}

.ml52 {
  margin-left: 52px !important;
}

.ml53 {
  margin-left: 53px !important;
}

.ml54 {
  margin-left: 54px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml56 {
  margin-left: 56px !important;
}

.ml57 {
  margin-left: 57px !important;
}

.ml58 {
  margin-left: 58px !important;
}

.ml59 {
  margin-left: 59px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.ml61 {
  margin-left: 61px !important;
}

.ml62 {
  margin-left: 62px !important;
}

.ml63 {
  margin-left: 63px !important;
}

.ml64 {
  margin-left: 64px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.ml66 {
  margin-left: 66px !important;
}

.ml67 {
  margin-left: 67px !important;
}

.ml68 {
  margin-left: 68px !important;
}

.ml69 {
  margin-left: 69px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.ml71 {
  margin-left: 71px !important;
}

.ml72 {
  margin-left: 72px !important;
}

.ml73 {
  margin-left: 73px !important;
}

.ml74 {
  margin-left: 74px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.ml76 {
  margin-left: 76px !important;
}

.ml77 {
  margin-left: 77px !important;
}

.ml78 {
  margin-left: 78px !important;
}

.ml79 {
  margin-left: 79px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.ml81 {
  margin-left: 81px !important;
}

.ml82 {
  margin-left: 82px !important;
}

.ml83 {
  margin-left: 83px !important;
}

.ml84 {
  margin-left: 84px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.ml86 {
  margin-left: 86px !important;
}

.ml87 {
  margin-left: 87px !important;
}

.ml88 {
  margin-left: 88px !important;
}

.ml89 {
  margin-left: 89px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.ml91 {
  margin-left: 91px !important;
}

.ml92 {
  margin-left: 92px !important;
}

.ml93 {
  margin-left: 93px !important;
}

.ml94 {
  margin-left: 94px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.ml96 {
  margin-left: 96px !important;
}

.ml97 {
  margin-left: 97px !important;
}

.ml98 {
  margin-left: 98px !important;
}

.ml99 {
  margin-left: 99px !important;
}

.ml100 {
  margin-left: 100px !important;
}

/*====================================================================================
笆�02.PADDING DEFAULT - PIXEL
====================================================================================*/
/*PADDING*/
.p00 {
  padding: 0px !important;
}

.p01 {
  padding: 1px !important;
}

.p02 {
  padding: 2px !important;
}

.p03 {
  padding: 3px !important;
}

.p04 {
  padding: 4px !important;
}

.p05 {
  padding: 5px !important;
}

.p06 {
  padding: 6px !important;
}

.p07 {
  padding: 7px !important;
}

.p08 {
  padding: 8px !important;
}

.p09 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p11 {
  padding: 11px !important;
}

.p12 {
  padding: 12px !important;
}

.p13 {
  padding: 13px !important;
}

.p14 {
  padding: 14px !important;
}

.p15 {
  padding: 15px !important;
}

.p16 {
  padding: 16px !important;
}

.p17 {
  padding: 17px !important;
}

.p18 {
  padding: 18px !important;
}

.p19 {
  padding: 19px !important;
}

.p20 {
  padding: 20px !important;
}

.p21 {
  padding: 21px !important;
}

.p22 {
  padding: 22px !important;
}

.p23 {
  padding: 23px !important;
}

.p24 {
  padding: 24px !important;
}

.p25 {
  padding: 25px !important;
}

.p26 {
  padding: 26px !important;
}

.p27 {
  padding: 27px !important;
}

.p28 {
  padding: 28px !important;
}

.p29 {
  padding: 29px !important;
}

.p30 {
  padding: 30px !important;
}

.p31 {
  padding: 31px !important;
}

.p32 {
  padding: 32px !important;
}

.p33 {
  padding: 33px !important;
}

.p34 {
  padding: 34px !important;
}

.p35 {
  padding: 35px !important;
}

.p36 {
  padding: 36px !important;
}

.p37 {
  padding: 37px !important;
}

.p38 {
  padding: 38px !important;
}

.p39 {
  padding: 39px !important;
}

.p40 {
  padding: 40px !important;
}

.p41 {
  padding: 41px !important;
}

.p42 {
  padding: 42px !important;
}

.p43 {
  padding: 43px !important;
}

.p44 {
  padding: 44px !important;
}

.p45 {
  padding: 45px !important;
}

.p46 {
  padding: 46px !important;
}

.p47 {
  padding: 47px !important;
}

.p48 {
  padding: 48px !important;
}

.p49 {
  padding: 49px !important;
}

.p50 {
  padding: 50px !important;
}

.p51 {
  padding: 51px !important;
}

.p52 {
  padding: 52px !important;
}

.p53 {
  padding: 53px !important;
}

.p54 {
  padding: 54px !important;
}

.p55 {
  padding: 55px !important;
}

.p56 {
  padding: 56px !important;
}

.p57 {
  padding: 57px !important;
}

.p58 {
  padding: 58px !important;
}

.p59 {
  padding: 59px !important;
}

.p60 {
  padding: 60px !important;
}

.p61 {
  padding: 61px !important;
}

.p62 {
  padding: 62px !important;
}

.p63 {
  padding: 63px !important;
}

.p64 {
  padding: 64px !important;
}

.p65 {
  padding: 65px !important;
}

.p66 {
  padding: 66px !important;
}

.p67 {
  padding: 67px !important;
}

.p68 {
  padding: 68px !important;
}

.p69 {
  padding: 69px !important;
}

.p70 {
  padding: 70px !important;
}

.p71 {
  padding: 71px !important;
}

.p72 {
  padding: 72px !important;
}

.p73 {
  padding: 73px !important;
}

.p74 {
  padding: 74px !important;
}

.p75 {
  padding: 75px !important;
}

.p76 {
  padding: 76px !important;
}

.p77 {
  padding: 77px !important;
}

.p78 {
  padding: 78px !important;
}

.p79 {
  padding: 79px !important;
}

.p80 {
  padding: 80px !important;
}

.p81 {
  padding: 81px !important;
}

.p82 {
  padding: 82px !important;
}

.p83 {
  padding: 83px !important;
}

.p84 {
  padding: 84px !important;
}

.p85 {
  padding: 85px !important;
}

.p86 {
  padding: 86px !important;
}

.p87 {
  padding: 87px !important;
}

.p88 {
  padding: 88px !important;
}

.p89 {
  padding: 89px !important;
}

.p90 {
  padding: 90px !important;
}

.p91 {
  padding: 91px !important;
}

.p92 {
  padding: 92px !important;
}

.p93 {
  padding: 93px !important;
}

.p94 {
  padding: 94px !important;
}

.p95 {
  padding: 95px !important;
}

.p96 {
  padding: 96px !important;
}

.p97 {
  padding: 97px !important;
}

.p98 {
  padding: 98px !important;
}

.p99 {
  padding: 99px !important;
}

.p100 {
  padding: 100px !important;
}

/*PADDING - TOP*/
.pt00 {
  padding-top: 0px !important;
}

.pt01 {
  padding-top: 1px !important;
}

.pt02 {
  padding-top: 2px !important;
}

.pt03 {
  padding-top: 3px !important;
}

.pt04 {
  padding-top: 4px !important;
}

.pt05 {
  padding-top: 5px !important;
}

.pt06 {
  padding-top: 6px !important;
}

.pt07 {
  padding-top: 7px !important;
}

.pt08 {
  padding-top: 8px !important;
}

.pt09 {
  padding-top: 9px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt11 {
  padding-top: 11px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.pt13 {
  padding-top: 13px !important;
}

.pt14 {
  padding-top: 14px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.pt17 {
  padding-top: 17px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pt19 {
  padding-top: 19px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt21 {
  padding-top: 21px !important;
}

.pt22 {
  padding-top: 22px !important;
}

.pt23 {
  padding-top: 23px !important;
}

.pt24 {
  padding-top: 24px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt26 {
  padding-top: 26px !important;
}

.pt27 {
  padding-top: 27px !important;
}

.pt28 {
  padding-top: 28px !important;
}

.pt29 {
  padding-top: 29px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt31 {
  padding-top: 31px !important;
}

.pt32 {
  padding-top: 32px !important;
}

.pt33 {
  padding-top: 33px !important;
}

.pt34 {
  padding-top: 34px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt36 {
  padding-top: 36px !important;
}

.pt37 {
  padding-top: 37px !important;
}

.pt38 {
  padding-top: 38px !important;
}

.pt39 {
  padding-top: 39px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt41 {
  padding-top: 41px !important;
}

.pt42 {
  padding-top: 42px !important;
}

.pt43 {
  padding-top: 43px !important;
}

.pt44 {
  padding-top: 44px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt46 {
  padding-top: 46px !important;
}

.pt47 {
  padding-top: 47px !important;
}

.pt48 {
  padding-top: 48px !important;
}

.pt49 {
  padding-top: 49px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt51 {
  padding-top: 51px !important;
}

.pt52 {
  padding-top: 52px !important;
}

.pt53 {
  padding-top: 53px !important;
}

.pt54 {
  padding-top: 54px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt56 {
  padding-top: 56px !important;
}

.pt57 {
  padding-top: 57px !important;
}

.pt58 {
  padding-top: 58px !important;
}

.pt59 {
  padding-top: 59px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt61 {
  padding-top: 61px !important;
}

.pt62 {
  padding-top: 62px !important;
}

.pt63 {
  padding-top: 63px !important;
}

.pt64 {
  padding-top: 64px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pt66 {
  padding-top: 66px !important;
}

.pt67 {
  padding-top: 67px !important;
}

.pt68 {
  padding-top: 68px !important;
}

.pt69 {
  padding-top: 69px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pt71 {
  padding-top: 71px !important;
}

.pt72 {
  padding-top: 72px !important;
}

.pt73 {
  padding-top: 73px !important;
}

.pt74 {
  padding-top: 74px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.pt76 {
  padding-top: 76px !important;
}

.pt77 {
  padding-top: 77px !important;
}

.pt78 {
  padding-top: 78px !important;
}

.pt79 {
  padding-top: 79px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pt81 {
  padding-top: 81px !important;
}

.pt82 {
  padding-top: 82px !important;
}

.pt83 {
  padding-top: 83px !important;
}

.pt84 {
  padding-top: 84px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.pt86 {
  padding-top: 86px !important;
}

.pt87 {
  padding-top: 87px !important;
}

.pt88 {
  padding-top: 88px !important;
}

.pt89 {
  padding-top: 89px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pt91 {
  padding-top: 91px !important;
}

.pt92 {
  padding-top: 92px !important;
}

.pt93 {
  padding-top: 93px !important;
}

.pt94 {
  padding-top: 94px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.pt96 {
  padding-top: 96px !important;
}

.pt97 {
  padding-top: 97px !important;
}

.pt98 {
  padding-top: 98px !important;
}

.pt99 {
  padding-top: 99px !important;
}

.pt100 {
  padding-top: 100px !important;
}

/*PADDING - RIGHT*/
.pr00 {
  padding-right: 0px !important;
}

.pr01 {
  padding-right: 1px !important;
}

.pr02 {
  padding-right: 2px !important;
}

.pr03 {
  padding-right: 3px !important;
}

.pr04 {
  padding-right: 4px !important;
}

.pr05 {
  padding-right: 5px !important;
}

.pr06 {
  padding-right: 6px !important;
}

.pr07 {
  padding-right: 7px !important;
}

.pr08 {
  padding-right: 8px !important;
}

.pr09 {
  padding-right: 9px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr11 {
  padding-right: 11px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pr13 {
  padding-right: 13px !important;
}

.pr14 {
  padding-right: 14px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr16 {
  padding-right: 16px !important;
}

.pr17 {
  padding-right: 17px !important;
}

.pr18 {
  padding-right: 18px !important;
}

.pr19 {
  padding-right: 19px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr21 {
  padding-right: 21px !important;
}

.pr22 {
  padding-right: 22px !important;
}

.pr23 {
  padding-right: 23px !important;
}

.pr24 {
  padding-right: 24px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr26 {
  padding-right: 26px !important;
}

.pr27 {
  padding-right: 27px !important;
}

.pr28 {
  padding-right: 28px !important;
}

.pr29 {
  padding-right: 29px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr31 {
  padding-right: 31px !important;
}

.pr32 {
  padding-right: 32px !important;
}

.pr33 {
  padding-right: 33px !important;
}

.pr34 {
  padding-right: 34px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr36 {
  padding-right: 36px !important;
}

.pr37 {
  padding-right: 37px !important;
}

.pr38 {
  padding-right: 38px !important;
}

.pr39 {
  padding-right: 39px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr41 {
  padding-right: 41px !important;
}

.pr42 {
  padding-right: 42px !important;
}

.pr43 {
  padding-right: 43px !important;
}

.pr44 {
  padding-right: 44px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr46 {
  padding-right: 46px !important;
}

.pr47 {
  padding-right: 47px !important;
}

.pr48 {
  padding-right: 48px !important;
}

.pr49 {
  padding-right: 49px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr51 {
  padding-right: 51px !important;
}

.pr52 {
  padding-right: 52px !important;
}

.pr53 {
  padding-right: 53px !important;
}

.pr54 {
  padding-right: 54px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr56 {
  padding-right: 56px !important;
}

.pr57 {
  padding-right: 57px !important;
}

.pr58 {
  padding-right: 58px !important;
}

.pr59 {
  padding-right: 59px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pr61 {
  padding-right: 61px !important;
}

.pr62 {
  padding-right: 62px !important;
}

.pr63 {
  padding-right: 63px !important;
}

.pr64 {
  padding-right: 64px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.pr66 {
  padding-right: 66px !important;
}

.pr67 {
  padding-right: 67px !important;
}

.pr68 {
  padding-right: 68px !important;
}

.pr69 {
  padding-right: 69px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pr71 {
  padding-right: 71px !important;
}

.pr72 {
  padding-right: 72px !important;
}

.pr73 {
  padding-right: 73px !important;
}

.pr74 {
  padding-right: 74px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.pr76 {
  padding-right: 76px !important;
}

.pr77 {
  padding-right: 77px !important;
}

.pr78 {
  padding-right: 78px !important;
}

.pr79 {
  padding-right: 79px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pr81 {
  padding-right: 81px !important;
}

.pr82 {
  padding-right: 82px !important;
}

.pr83 {
  padding-right: 83px !important;
}

.pr84 {
  padding-right: 84px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.pr86 {
  padding-right: 86px !important;
}

.pr87 {
  padding-right: 87px !important;
}

.pr88 {
  padding-right: 88px !important;
}

.pr89 {
  padding-right: 89px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pr91 {
  padding-right: 91px !important;
}

.pr92 {
  padding-right: 92px !important;
}

.pr93 {
  padding-right: 93px !important;
}

.pr94 {
  padding-right: 94px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.pr96 {
  padding-right: 96px !important;
}

.pr97 {
  padding-right: 97px !important;
}

.pr98 {
  padding-right: 98px !important;
}

.pr99 {
  padding-right: 99px !important;
}

.pr100 {
  padding-right: 100px !important;
}

/*PADDING - BOTTOM*/
.pb00 {
  padding-bottom: 0px !important;
}

.pb01 {
  padding-bottom: 1px !important;
}

.pb02 {
  padding-bottom: 2px !important;
}

.pb03 {
  padding-bottom: 3px !important;
}

.pb04 {
  padding-bottom: 4px !important;
}

.pb05 {
  padding-bottom: 5px !important;
}

.pb06 {
  padding-bottom: 6px !important;
}

.pb07 {
  padding-bottom: 7px !important;
}

.pb08 {
  padding-bottom: 8px !important;
}

.pb09 {
  padding-bottom: 9px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb11 {
  padding-bottom: 11px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.pb13 {
  padding-bottom: 13px !important;
}

.pb14 {
  padding-bottom: 14px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.pb17 {
  padding-bottom: 17px !important;
}

.pb18 {
  padding-bottom: 18px !important;
}

.pb19 {
  padding-bottom: 19px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb21 {
  padding-bottom: 21px !important;
}

.pb22 {
  padding-bottom: 22px !important;
}

.pb23 {
  padding-bottom: 23px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb26 {
  padding-bottom: 26px !important;
}

.pb27 {
  padding-bottom: 27px !important;
}

.pb28 {
  padding-bottom: 28px !important;
}

.pb29 {
  padding-bottom: 29px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb31 {
  padding-bottom: 31px !important;
}

.pb32 {
  padding-bottom: 32px !important;
}

.pb33 {
  padding-bottom: 33px !important;
}

.pb34 {
  padding-bottom: 34px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb36 {
  padding-bottom: 36px !important;
}

.pb37 {
  padding-bottom: 37px !important;
}

.pb38 {
  padding-bottom: 38px !important;
}

.pb39 {
  padding-bottom: 39px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb41 {
  padding-bottom: 41px !important;
}

.pb42 {
  padding-bottom: 42px !important;
}

.pb43 {
  padding-bottom: 43px !important;
}

.pb44 {
  padding-bottom: 44px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb46 {
  padding-bottom: 46px !important;
}

.pb47 {
  padding-bottom: 47px !important;
}

.pb48 {
  padding-bottom: 48px !important;
}

.pb49 {
  padding-bottom: 49px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb51 {
  padding-bottom: 51px !important;
}

.pb52 {
  padding-bottom: 52px !important;
}

.pb53 {
  padding-bottom: 53px !important;
}

.pb54 {
  padding-bottom: 54px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb56 {
  padding-bottom: 56px !important;
}

.pb57 {
  padding-bottom: 57px !important;
}

.pb58 {
  padding-bottom: 58px !important;
}

.pb59 {
  padding-bottom: 59px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb61 {
  padding-bottom: 61px !important;
}

.pb62 {
  padding-bottom: 62px !important;
}

.pb63 {
  padding-bottom: 63px !important;
}

.pb64 {
  padding-bottom: 64px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.pb66 {
  padding-bottom: 66px !important;
}

.pb67 {
  padding-bottom: 67px !important;
}

.pb68 {
  padding-bottom: 68px !important;
}

.pb69 {
  padding-bottom: 69px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb71 {
  padding-bottom: 71px !important;
}

.pb72 {
  padding-bottom: 72px !important;
}

.pb73 {
  padding-bottom: 73px !important;
}

.pb74 {
  padding-bottom: 74px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.pb76 {
  padding-bottom: 76px !important;
}

.pb77 {
  padding-bottom: 77px !important;
}

.pb78 {
  padding-bottom: 78px !important;
}

.pb79 {
  padding-bottom: 79px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pb81 {
  padding-bottom: 81px !important;
}

.pb82 {
  padding-bottom: 82px !important;
}

.pb83 {
  padding-bottom: 83px !important;
}

.pb84 {
  padding-bottom: 84px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.pb86 {
  padding-bottom: 86px !important;
}

.pb87 {
  padding-bottom: 87px !important;
}

.pb88 {
  padding-bottom: 88px !important;
}

.pb89 {
  padding-bottom: 89px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pb91 {
  padding-bottom: 91px !important;
}

.pb92 {
  padding-bottom: 92px !important;
}

.pb93 {
  padding-bottom: 93px !important;
}

.pb94 {
  padding-bottom: 94px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.pb96 {
  padding-bottom: 96px !important;
}

.pb97 {
  padding-bottom: 97px !important;
}

.pb98 {
  padding-bottom: 98px !important;
}

.pb99 {
  padding-bottom: 99px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

/*PADDING - LEFT*/
.pl00 {
  padding-left: 0px !important;
}

.pl01 {
  padding-left: 1px !important;
}

.pl02 {
  padding-left: 2px !important;
}

.pl03 {
  padding-left: 3px !important;
}

.pl04 {
  padding-left: 4px !important;
}

.pl05 {
  padding-left: 5px !important;
}

.pl06 {
  padding-left: 6px !important;
}

.pl07 {
  padding-left: 7px !important;
}

.pl08 {
  padding-left: 8px !important;
}

.pl09 {
  padding-left: 9px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl11 {
  padding-left: 11px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.pl13 {
  padding-left: 13px !important;
}

.pl14 {
  padding-left: 14px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.pl17 {
  padding-left: 17px !important;
}

.pl18 {
  padding-left: 18px !important;
}

.pl19 {
  padding-left: 19px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl21 {
  padding-left: 21px !important;
}

.pl22 {
  padding-left: 22px !important;
}

.pl23 {
  padding-left: 23px !important;
}

.pl24 {
  padding-left: 24px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl26 {
  padding-left: 26px !important;
}

.pl27 {
  padding-left: 27px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.pl29 {
  padding-left: 29px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl31 {
  padding-left: 31px !important;
}

.pl32 {
  padding-left: 32px !important;
}

.pl33 {
  padding-left: 33px !important;
}

.pl34 {
  padding-left: 34px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl36 {
  padding-left: 36px !important;
}

.pl37 {
  padding-left: 37px !important;
}

.pl38 {
  padding-left: 38px !important;
}

.pl39 {
  padding-left: 39px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl41 {
  padding-left: 41px !important;
}

.pl42 {
  padding-left: 42px !important;
}

.pl43 {
  padding-left: 43px !important;
}

.pl44 {
  padding-left: 44px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl46 {
  padding-left: 46px !important;
}

.pl47 {
  padding-left: 47px !important;
}

.pl48 {
  padding-left: 48px !important;
}

.pl49 {
  padding-left: 49px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl51 {
  padding-left: 51px !important;
}

.pl52 {
  padding-left: 52px !important;
}

.pl53 {
  padding-left: 53px !important;
}

.pl54 {
  padding-left: 54px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl56 {
  padding-left: 56px !important;
}

.pl57 {
  padding-left: 57px !important;
}

.pl58 {
  padding-left: 58px !important;
}

.pl59 {
  padding-left: 59px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pl61 {
  padding-left: 61px !important;
}

.pl62 {
  padding-left: 62px !important;
}

.pl63 {
  padding-left: 63px !important;
}

.pl64 {
  padding-left: 64px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.pl66 {
  padding-left: 66px !important;
}

.pl67 {
  padding-left: 67px !important;
}

.pl68 {
  padding-left: 68px !important;
}

.pl69 {
  padding-left: 69px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pl71 {
  padding-left: 71px !important;
}

.pl72 {
  padding-left: 72px !important;
}

.pl73 {
  padding-left: 73px !important;
}

.pl74 {
  padding-left: 74px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.pl76 {
  padding-left: 76px !important;
}

.pl77 {
  padding-left: 77px !important;
}

.pl78 {
  padding-left: 78px !important;
}

.pl79 {
  padding-left: 79px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pl81 {
  padding-left: 81px !important;
}

.pl82 {
  padding-left: 82px !important;
}

.pl83 {
  padding-left: 83px !important;
}

.pl84 {
  padding-left: 84px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.pl86 {
  padding-left: 86px !important;
}

.pl87 {
  padding-left: 87px !important;
}

.pl88 {
  padding-left: 88px !important;
}

.pl89 {
  padding-left: 89px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pl91 {
  padding-left: 91px !important;
}

.pl92 {
  padding-left: 92px !important;
}

.pl93 {
  padding-left: 93px !important;
}

.pl94 {
  padding-left: 94px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.pl96 {
  padding-left: 96px !important;
}

.pl97 {
  padding-left: 97px !important;
}

.pl98 {
  padding-left: 98px !important;
}

.pl99 {
  padding-left: 99px !important;
}

.pl100 {
  padding-left: 100px !important;
}

/*====================================================================================
笆�03.MARGIN DEFAULT - PERCENT
====================================================================================*/
/*MARGIN*/
.m00pc {
  margin: 0% !important;
}

.m01pc {
  margin: 1% !important;
}

.m02pc {
  margin: 2% !important;
}

.m03pc {
  margin: 3% !important;
}

.m04pc {
  margin: 4% !important;
}

.m05pc {
  margin: 5% !important;
}

.m06pc {
  margin: 6% !important;
}

.m07pc {
  margin: 7% !important;
}

.m08pc {
  margin: 8% !important;
}

.m09pc {
  margin: 9% !important;
}

.m10pc {
  margin: 10% !important;
}

.m11pc {
  margin: 11% !important;
}

.m12pc {
  margin: 12% !important;
}

.m13pc {
  margin: 13% !important;
}

.m14pc {
  margin: 14% !important;
}

.m15pc {
  margin: 15% !important;
}

.m16pc {
  margin: 16% !important;
}

.m17pc {
  margin: 17% !important;
}

.m18pc {
  margin: 18% !important;
}

.m19pc {
  margin: 19% !important;
}

.m20pc {
  margin: 20% !important;
}

.m21pc {
  margin: 21% !important;
}

.m22pc {
  margin: 22% !important;
}

.m23pc {
  margin: 23% !important;
}

.m24pc {
  margin: 24% !important;
}

.m25pc {
  margin: 25% !important;
}

.m26pc {
  margin: 26% !important;
}

.m27pc {
  margin: 27% !important;
}

.m28pc {
  margin: 28% !important;
}

.m29pc {
  margin: 29% !important;
}

.m30pc {
  margin: 30% !important;
}

.m31pc {
  margin: 31% !important;
}

.m32pc {
  margin: 32% !important;
}

.m33pc {
  margin: 33% !important;
}

.m34pc {
  margin: 34% !important;
}

.m35pc {
  margin: 35% !important;
}

.m36pc {
  margin: 36% !important;
}

.m37pc {
  margin: 37% !important;
}

.m38pc {
  margin: 38% !important;
}

.m39pc {
  margin: 39% !important;
}

.m40pc {
  margin: 40% !important;
}

.m41pc {
  margin: 41% !important;
}

.m42pc {
  margin: 42% !important;
}

.m43pc {
  margin: 43% !important;
}

.m44pc {
  margin: 44% !important;
}

.m45pc {
  margin: 45% !important;
}

.m46pc {
  margin: 46% !important;
}

.m47pc {
  margin: 47% !important;
}

.m48pc {
  margin: 48% !important;
}

.m49pc {
  margin: 49% !important;
}

.m50pc {
  margin: 50% !important;
}

.m51pc {
  margin: 51% !important;
}

.m52pc {
  margin: 52% !important;
}

.m53pc {
  margin: 53% !important;
}

.m54pc {
  margin: 54% !important;
}

.m55pc {
  margin: 55% !important;
}

.m56pc {
  margin: 56% !important;
}

.m57pc {
  margin: 57% !important;
}

.m58pc {
  margin: 58% !important;
}

.m59pc {
  margin: 59% !important;
}

.m60pc {
  margin: 60% !important;
}

.m61pc {
  margin: 61% !important;
}

.m62pc {
  margin: 62% !important;
}

.m63pc {
  margin: 63% !important;
}

.m64pc {
  margin: 64% !important;
}

.m65pc {
  margin: 65% !important;
}

.m66pc {
  margin: 66% !important;
}

.m67pc {
  margin: 67% !important;
}

.m68pc {
  margin: 68% !important;
}

.m69pc {
  margin: 69% !important;
}

.m70pc {
  margin: 70% !important;
}

.m71pc {
  margin: 71% !important;
}

.m72pc {
  margin: 72% !important;
}

.m73pc {
  margin: 73% !important;
}

.m74pc {
  margin: 74% !important;
}

.m75pc {
  margin: 75% !important;
}

.m76pc {
  margin: 76% !important;
}

.m77pc {
  margin: 77% !important;
}

.m78pc {
  margin: 78% !important;
}

.m79pc {
  margin: 79% !important;
}

.m80pc {
  margin: 80% !important;
}

.m81pc {
  margin: 81% !important;
}

.m82pc {
  margin: 82% !important;
}

.m83pc {
  margin: 83% !important;
}

.m84pc {
  margin: 84% !important;
}

.m85pc {
  margin: 85% !important;
}

.m86pc {
  margin: 86% !important;
}

.m87pc {
  margin: 87% !important;
}

.m88pc {
  margin: 88% !important;
}

.m89pc {
  margin: 89% !important;
}

.m90pc {
  margin: 90% !important;
}

.m91pc {
  margin: 91% !important;
}

.m92pc {
  margin: 92% !important;
}

.m93pc {
  margin: 93% !important;
}

.m94pc {
  margin: 94% !important;
}

.m95pc {
  margin: 95% !important;
}

.m96pc {
  margin: 96% !important;
}

.m97pc {
  margin: 97% !important;
}

.m98pc {
  margin: 98% !important;
}

.m99pc {
  margin: 99% !important;
}

.m100pc {
  margin: 100% !important;
}

/*MARGIN - TOP*/
.mt00pc {
  margin-top: 0% !important;
}

.mt01pc {
  margin-top: 1% !important;
}

.mt02pc {
  margin-top: 2% !important;
}

.mt03pc {
  margin-top: 3% !important;
}

.mt04pc {
  margin-top: 4% !important;
}

.mt05pc {
  margin-top: 5% !important;
}

.mt06pc {
  margin-top: 6% !important;
}

.mt07pc {
  margin-top: 7% !important;
}

.mt08pc {
  margin-top: 8% !important;
}

.mt09pc {
  margin-top: 9% !important;
}

.mt10pc {
  margin-top: 10% !important;
}

.mt11pc {
  margin-top: 11% !important;
}

.mt12pc {
  margin-top: 12% !important;
}

.mt13pc {
  margin-top: 13% !important;
}

.mt14pc {
  margin-top: 14% !important;
}

.mt15pc {
  margin-top: 15% !important;
}

.mt16pc {
  margin-top: 16% !important;
}

.mt17pc {
  margin-top: 17% !important;
}

.mt18pc {
  margin-top: 18% !important;
}

.mt19pc {
  margin-top: 19% !important;
}

.mt20pc {
  margin-top: 20% !important;
}

.mt21pc {
  margin-top: 21% !important;
}

.mt22pc {
  margin-top: 22% !important;
}

.mt23pc {
  margin-top: 23% !important;
}

.mt24pc {
  margin-top: 24% !important;
}

.mt25pc {
  margin-top: 25% !important;
}

.mt26pc {
  margin-top: 26% !important;
}

.mt27pc {
  margin-top: 27% !important;
}

.mt28pc {
  margin-top: 28% !important;
}

.mt29pc {
  margin-top: 29% !important;
}

.mt30pc {
  margin-top: 30% !important;
}

.mt31pc {
  margin-top: 31% !important;
}

.mt32pc {
  margin-top: 32% !important;
}

.mt33pc {
  margin-top: 33% !important;
}

.mt34pc {
  margin-top: 34% !important;
}

.mt35pc {
  margin-top: 35% !important;
}

.mt36pc {
  margin-top: 36% !important;
}

.mt37pc {
  margin-top: 37% !important;
}

.mt38pc {
  margin-top: 38% !important;
}

.mt39pc {
  margin-top: 39% !important;
}

.mt40pc {
  margin-top: 40% !important;
}

.mt41pc {
  margin-top: 41% !important;
}

.mt42pc {
  margin-top: 42% !important;
}

.mt43pc {
  margin-top: 43% !important;
}

.mt44pc {
  margin-top: 44% !important;
}

.mt45pc {
  margin-top: 45% !important;
}

.mt46pc {
  margin-top: 46% !important;
}

.mt47pc {
  margin-top: 47% !important;
}

.mt48pc {
  margin-top: 48% !important;
}

.mt49pc {
  margin-top: 49% !important;
}

.mt50pc {
  margin-top: 50% !important;
}

.mt51pc {
  margin-top: 51% !important;
}

.mt52pc {
  margin-top: 52% !important;
}

.mt53pc {
  margin-top: 53% !important;
}

.mt54pc {
  margin-top: 54% !important;
}

.mt55pc {
  margin-top: 55% !important;
}

.mt56pc {
  margin-top: 56% !important;
}

.mt57pc {
  margin-top: 57% !important;
}

.mt58pc {
  margin-top: 58% !important;
}

.mt59pc {
  margin-top: 59% !important;
}

.mt60pc {
  margin-top: 60% !important;
}

.mt61pc {
  margin-top: 61% !important;
}

.mt62pc {
  margin-top: 62% !important;
}

.mt63pc {
  margin-top: 63% !important;
}

.mt64pc {
  margin-top: 64% !important;
}

.mt65pc {
  margin-top: 65% !important;
}

.mt66pc {
  margin-top: 66% !important;
}

.mt67pc {
  margin-top: 67% !important;
}

.mt68pc {
  margin-top: 68% !important;
}

.mt69pc {
  margin-top: 69% !important;
}

.mt70pc {
  margin-top: 70% !important;
}

.mt71pc {
  margin-top: 71% !important;
}

.mt72pc {
  margin-top: 72% !important;
}

.mt73pc {
  margin-top: 73% !important;
}

.mt74pc {
  margin-top: 74% !important;
}

.mt75pc {
  margin-top: 75% !important;
}

.mt76pc {
  margin-top: 76% !important;
}

.mt77pc {
  margin-top: 77% !important;
}

.mt78pc {
  margin-top: 78% !important;
}

.mt79pc {
  margin-top: 79% !important;
}

.mt80pc {
  margin-top: 80% !important;
}

.mt81pc {
  margin-top: 81% !important;
}

.mt82pc {
  margin-top: 82% !important;
}

.mt83pc {
  margin-top: 83% !important;
}

.mt84pc {
  margin-top: 84% !important;
}

.mt85pc {
  margin-top: 85% !important;
}

.mt86pc {
  margin-top: 86% !important;
}

.mt87pc {
  margin-top: 87% !important;
}

.mt88pc {
  margin-top: 88% !important;
}

.mt89pc {
  margin-top: 89% !important;
}

.mt90pc {
  margin-top: 90% !important;
}

.mt91pc {
  margin-top: 91% !important;
}

.mt92pc {
  margin-top: 92% !important;
}

.mt93pc {
  margin-top: 93% !important;
}

.mt94pc {
  margin-top: 94% !important;
}

.mt95pc {
  margin-top: 95% !important;
}

.mt96pc {
  margin-top: 96% !important;
}

.mt97pc {
  margin-top: 97% !important;
}

.mt98pc {
  margin-top: 98% !important;
}

.mt99pc {
  margin-top: 99% !important;
}

.mt100pc {
  margin-top: 100% !important;
}

/*MARGIN - RIGHT*/
.mr00pc {
  margin-right: 0% !important;
}

.mr01pc {
  margin-right: 1% !important;
}

.mr02pc {
  margin-right: 2% !important;
}

.mr03pc {
  margin-right: 3% !important;
}

.mr04pc {
  margin-right: 4% !important;
}

.mr05pc {
  margin-right: 5% !important;
}

.mr06pc {
  margin-right: 6% !important;
}

.mr07pc {
  margin-right: 7% !important;
}

.mr08pc {
  margin-right: 8% !important;
}

.mr09pc {
  margin-right: 9% !important;
}

.mr10pc {
  margin-right: 10% !important;
}

.mr11pc {
  margin-right: 11% !important;
}

.mr12pc {
  margin-right: 12% !important;
}

.mr13pc {
  margin-right: 13% !important;
}

.mr14pc {
  margin-right: 14% !important;
}

.mr15pc {
  margin-right: 15% !important;
}

.mr16pc {
  margin-right: 16% !important;
}

.mr17pc {
  margin-right: 17% !important;
}

.mr18pc {
  margin-right: 18% !important;
}

.mr19pc {
  margin-right: 19% !important;
}

.mr20pc {
  margin-right: 20% !important;
}

.mr21pc {
  margin-right: 21% !important;
}

.mr22pc {
  margin-right: 22% !important;
}

.mr23pc {
  margin-right: 23% !important;
}

.mr24pc {
  margin-right: 24% !important;
}

.mr25pc {
  margin-right: 25% !important;
}

.mr26pc {
  margin-right: 26% !important;
}

.mr27pc {
  margin-right: 27% !important;
}

.mr28pc {
  margin-right: 28% !important;
}

.mr29pc {
  margin-right: 29% !important;
}

.mr30pc {
  margin-right: 30% !important;
}

.mr31pc {
  margin-right: 31% !important;
}

.mr32pc {
  margin-right: 32% !important;
}

.mr33pc {
  margin-right: 33% !important;
}

.mr34pc {
  margin-right: 34% !important;
}

.mr35pc {
  margin-right: 35% !important;
}

.mr36pc {
  margin-right: 36% !important;
}

.mr37pc {
  margin-right: 37% !important;
}

.mr38pc {
  margin-right: 38% !important;
}

.mr39pc {
  margin-right: 39% !important;
}

.mr40pc {
  margin-right: 40% !important;
}

.mr41pc {
  margin-right: 41% !important;
}

.mr42pc {
  margin-right: 42% !important;
}

.mr43pc {
  margin-right: 43% !important;
}

.mr44pc {
  margin-right: 44% !important;
}

.mr45pc {
  margin-right: 45% !important;
}

.mr46pc {
  margin-right: 46% !important;
}

.mr47pc {
  margin-right: 47% !important;
}

.mr48pc {
  margin-right: 48% !important;
}

.mr49pc {
  margin-right: 49% !important;
}

.mr50pc {
  margin-right: 50% !important;
}

.mr51pc {
  margin-right: 51% !important;
}

.mr52pc {
  margin-right: 52% !important;
}

.mr53pc {
  margin-right: 53% !important;
}

.mr54pc {
  margin-right: 54% !important;
}

.mr55pc {
  margin-right: 55% !important;
}

.mr56pc {
  margin-right: 56% !important;
}

.mr57pc {
  margin-right: 57% !important;
}

.mr58pc {
  margin-right: 58% !important;
}

.mr59pc {
  margin-right: 59% !important;
}

.mr60pc {
  margin-right: 60% !important;
}

.mr61pc {
  margin-right: 61% !important;
}

.mr62pc {
  margin-right: 62% !important;
}

.mr63pc {
  margin-right: 63% !important;
}

.mr64pc {
  margin-right: 64% !important;
}

.mr65pc {
  margin-right: 65% !important;
}

.mr66pc {
  margin-right: 66% !important;
}

.mr67pc {
  margin-right: 67% !important;
}

.mr68pc {
  margin-right: 68% !important;
}

.mr69pc {
  margin-right: 69% !important;
}

.mr70pc {
  margin-right: 70% !important;
}

.mr71pc {
  margin-right: 71% !important;
}

.mr72pc {
  margin-right: 72% !important;
}

.mr73pc {
  margin-right: 73% !important;
}

.mr74pc {
  margin-right: 74% !important;
}

.mr75pc {
  margin-right: 75% !important;
}

.mr76pc {
  margin-right: 76% !important;
}

.mr77pc {
  margin-right: 77% !important;
}

.mr78pc {
  margin-right: 78% !important;
}

.mr79pc {
  margin-right: 79% !important;
}

.mr80pc {
  margin-right: 80% !important;
}

.mr81pc {
  margin-right: 81% !important;
}

.mr82pc {
  margin-right: 82% !important;
}

.mr83pc {
  margin-right: 83% !important;
}

.mr84pc {
  margin-right: 84% !important;
}

.mr85pc {
  margin-right: 85% !important;
}

.mr86pc {
  margin-right: 86% !important;
}

.mr87pc {
  margin-right: 87% !important;
}

.mr88pc {
  margin-right: 88% !important;
}

.mr89pc {
  margin-right: 89% !important;
}

.mr90pc {
  margin-right: 90% !important;
}

.mr91pc {
  margin-right: 91% !important;
}

.mr92pc {
  margin-right: 92% !important;
}

.mr93pc {
  margin-right: 93% !important;
}

.mr94pc {
  margin-right: 94% !important;
}

.mr95pc {
  margin-right: 95% !important;
}

.mr96pc {
  margin-right: 96% !important;
}

.mr97pc {
  margin-right: 97% !important;
}

.mr98pc {
  margin-right: 98% !important;
}

.mr99pc {
  margin-right: 99% !important;
}

.mr100pc {
  margin-right: 100% !important;
}

/*MARGIN - BOTTOM*/
.mb00pc {
  margin-bottom: 0% !important;
}

.mb01pc {
  margin-bottom: 1% !important;
}

.mb02pc {
  margin-bottom: 2% !important;
}

.mb03pc {
  margin-bottom: 3% !important;
}

.mb04pc {
  margin-bottom: 4% !important;
}

.mb05pc {
  margin-bottom: 5% !important;
}

.mb06pc {
  margin-bottom: 6% !important;
}

.mb07pc {
  margin-bottom: 7% !important;
}

.mb08pc {
  margin-bottom: 8% !important;
}

.mb09pc {
  margin-bottom: 9% !important;
}

.mb10pc {
  margin-bottom: 10% !important;
}

.mb11pc {
  margin-bottom: 11% !important;
}

.mb12pc {
  margin-bottom: 12% !important;
}

.mb13pc {
  margin-bottom: 13% !important;
}

.mb14pc {
  margin-bottom: 14% !important;
}

.mb15pc {
  margin-bottom: 15% !important;
}

.mb16pc {
  margin-bottom: 16% !important;
}

.mb17pc {
  margin-bottom: 17% !important;
}

.mb18pc {
  margin-bottom: 18% !important;
}

.mb19pc {
  margin-bottom: 19% !important;
}

.mb20pc {
  margin-bottom: 20% !important;
}

.mb21pc {
  margin-bottom: 21% !important;
}

.mb22pc {
  margin-bottom: 22% !important;
}

.mb23pc {
  margin-bottom: 23% !important;
}

.mb24pc {
  margin-bottom: 24% !important;
}

.mb25pc {
  margin-bottom: 25% !important;
}

.mb26pc {
  margin-bottom: 26% !important;
}

.mb27pc {
  margin-bottom: 27% !important;
}

.mb28pc {
  margin-bottom: 28% !important;
}

.mb29pc {
  margin-bottom: 29% !important;
}

.mb30pc {
  margin-bottom: 30% !important;
}

.mb31pc {
  margin-bottom: 31% !important;
}

.mb32pc {
  margin-bottom: 32% !important;
}

.mb33pc {
  margin-bottom: 33% !important;
}

.mb34pc {
  margin-bottom: 34% !important;
}

.mb35pc {
  margin-bottom: 35% !important;
}

.mb36pc {
  margin-bottom: 36% !important;
}

.mb37pc {
  margin-bottom: 37% !important;
}

.mb38pc {
  margin-bottom: 38% !important;
}

.mb39pc {
  margin-bottom: 39% !important;
}

.mb40pc {
  margin-bottom: 40% !important;
}

.mb41pc {
  margin-bottom: 41% !important;
}

.mb42pc {
  margin-bottom: 42% !important;
}

.mb43pc {
  margin-bottom: 43% !important;
}

.mb44pc {
  margin-bottom: 44% !important;
}

.mb45pc {
  margin-bottom: 45% !important;
}

.mb46pc {
  margin-bottom: 46% !important;
}

.mb47pc {
  margin-bottom: 47% !important;
}

.mb48pc {
  margin-bottom: 48% !important;
}

.mb49pc {
  margin-bottom: 49% !important;
}

.mb50pc {
  margin-bottom: 50% !important;
}

.mb51pc {
  margin-bottom: 51% !important;
}

.mb52pc {
  margin-bottom: 52% !important;
}

.mb53pc {
  margin-bottom: 53% !important;
}

.mb54pc {
  margin-bottom: 54% !important;
}

.mb55pc {
  margin-bottom: 55% !important;
}

.mb56pc {
  margin-bottom: 56% !important;
}

.mb57pc {
  margin-bottom: 57% !important;
}

.mb58pc {
  margin-bottom: 58% !important;
}

.mb59pc {
  margin-bottom: 59% !important;
}

.mb60pc {
  margin-bottom: 60% !important;
}

.mb61pc {
  margin-bottom: 61% !important;
}

.mb62pc {
  margin-bottom: 62% !important;
}

.mb63pc {
  margin-bottom: 63% !important;
}

.mb64pc {
  margin-bottom: 64% !important;
}

.mb65pc {
  margin-bottom: 65% !important;
}

.mb66pc {
  margin-bottom: 66% !important;
}

.mb67pc {
  margin-bottom: 67% !important;
}

.mb68pc {
  margin-bottom: 68% !important;
}

.mb69pc {
  margin-bottom: 69% !important;
}

.mb70pc {
  margin-bottom: 70% !important;
}

.mb71pc {
  margin-bottom: 71% !important;
}

.mb72pc {
  margin-bottom: 72% !important;
}

.mb73pc {
  margin-bottom: 73% !important;
}

.mb74pc {
  margin-bottom: 74% !important;
}

.mb75pc {
  margin-bottom: 75% !important;
}

.mb76pc {
  margin-bottom: 76% !important;
}

.mb77pc {
  margin-bottom: 77% !important;
}

.mb78pc {
  margin-bottom: 78% !important;
}

.mb79pc {
  margin-bottom: 79% !important;
}

.mb80pc {
  margin-bottom: 80% !important;
}

.mb81pc {
  margin-bottom: 81% !important;
}

.mb82pc {
  margin-bottom: 82% !important;
}

.mb83pc {
  margin-bottom: 83% !important;
}

.mb84pc {
  margin-bottom: 84% !important;
}

.mb85pc {
  margin-bottom: 85% !important;
}

.mb86pc {
  margin-bottom: 86% !important;
}

.mb87pc {
  margin-bottom: 87% !important;
}

.mb88pc {
  margin-bottom: 88% !important;
}

.mb89pc {
  margin-bottom: 89% !important;
}

.mb90pc {
  margin-bottom: 90% !important;
}

.mb91pc {
  margin-bottom: 91% !important;
}

.mb92pc {
  margin-bottom: 92% !important;
}

.mb93pc {
  margin-bottom: 93% !important;
}

.mb94pc {
  margin-bottom: 94% !important;
}

.mb95pc {
  margin-bottom: 95% !important;
}

.mb96pc {
  margin-bottom: 96% !important;
}

.mb97pc {
  margin-bottom: 97% !important;
}

.mb98pc {
  margin-bottom: 98% !important;
}

.mb99pc {
  margin-bottom: 99% !important;
}

.mb100pc {
  margin-bottom: 100% !important;
}

/*MARGIN - LEFT*/
.ml00pc {
  margin-left: 0% !important;
}

.ml01pc {
  margin-left: 1% !important;
}

.ml02pc {
  margin-left: 2% !important;
}

.ml03pc {
  margin-left: 3% !important;
}

.ml04pc {
  margin-left: 4% !important;
}

.ml05pc {
  margin-left: 5% !important;
}

.ml06pc {
  margin-left: 6% !important;
}

.ml07pc {
  margin-left: 7% !important;
}

.ml08pc {
  margin-left: 8% !important;
}

.ml09pc {
  margin-left: 9% !important;
}

.ml10pc {
  margin-left: 10% !important;
}

.ml11pc {
  margin-left: 11% !important;
}

.ml12pc {
  margin-left: 12% !important;
}

.ml13pc {
  margin-left: 13% !important;
}

.ml14pc {
  margin-left: 14% !important;
}

.ml15pc {
  margin-left: 15% !important;
}

.ml16pc {
  margin-left: 16% !important;
}

.ml17pc {
  margin-left: 17% !important;
}

.ml18pc {
  margin-left: 18% !important;
}

.ml19pc {
  margin-left: 19% !important;
}

.ml20pc {
  margin-left: 20% !important;
}

.ml21pc {
  margin-left: 21% !important;
}

.ml22pc {
  margin-left: 22% !important;
}

.ml23pc {
  margin-left: 23% !important;
}

.ml24pc {
  margin-left: 24% !important;
}

.ml25pc {
  margin-left: 25% !important;
}

.ml26pc {
  margin-left: 26% !important;
}

.ml27pc {
  margin-left: 27% !important;
}

.ml28pc {
  margin-left: 28% !important;
}

.ml29pc {
  margin-left: 29% !important;
}

.ml30pc {
  margin-left: 30% !important;
}

.ml31pc {
  margin-left: 31% !important;
}

.ml32pc {
  margin-left: 32% !important;
}

.ml33pc {
  margin-left: 33% !important;
}

.ml34pc {
  margin-left: 34% !important;
}

.ml35pc {
  margin-left: 35% !important;
}

.ml36pc {
  margin-left: 36% !important;
}

.ml37pc {
  margin-left: 37% !important;
}

.ml38pc {
  margin-left: 38% !important;
}

.ml39pc {
  margin-left: 39% !important;
}

.ml40pc {
  margin-left: 40% !important;
}

.ml41pc {
  margin-left: 41% !important;
}

.ml42pc {
  margin-left: 42% !important;
}

.ml43pc {
  margin-left: 43% !important;
}

.ml44pc {
  margin-left: 44% !important;
}

.ml45pc {
  margin-left: 45% !important;
}

.ml46pc {
  margin-left: 46% !important;
}

.ml47pc {
  margin-left: 47% !important;
}

.ml48pc {
  margin-left: 48% !important;
}

.ml49pc {
  margin-left: 49% !important;
}

.ml50pc {
  margin-left: 50% !important;
}

.ml51pc {
  margin-left: 51% !important;
}

.ml52pc {
  margin-left: 52% !important;
}

.ml53pc {
  margin-left: 53% !important;
}

.ml54pc {
  margin-left: 54% !important;
}

.ml55pc {
  margin-left: 55% !important;
}

.ml56pc {
  margin-left: 56% !important;
}

.ml57pc {
  margin-left: 57% !important;
}

.ml58pc {
  margin-left: 58% !important;
}

.ml59pc {
  margin-left: 59% !important;
}

.ml60pc {
  margin-left: 60% !important;
}

.ml61pc {
  margin-left: 61% !important;
}

.ml62pc {
  margin-left: 62% !important;
}

.ml63pc {
  margin-left: 63% !important;
}

.ml64pc {
  margin-left: 64% !important;
}

.ml65pc {
  margin-left: 65% !important;
}

.ml66pc {
  margin-left: 66% !important;
}

.ml67pc {
  margin-left: 67% !important;
}

.ml68pc {
  margin-left: 68% !important;
}

.ml69pc {
  margin-left: 69% !important;
}

.ml70pc {
  margin-left: 70% !important;
}

.ml71pc {
  margin-left: 71% !important;
}

.ml72pc {
  margin-left: 72% !important;
}

.ml73pc {
  margin-left: 73% !important;
}

.ml74pc {
  margin-left: 74% !important;
}

.ml75pc {
  margin-left: 75% !important;
}

.ml76pc {
  margin-left: 76% !important;
}

.ml77pc {
  margin-left: 77% !important;
}

.ml78pc {
  margin-left: 78% !important;
}

.ml79pc {
  margin-left: 79% !important;
}

.ml80pc {
  margin-left: 80% !important;
}

.ml81pc {
  margin-left: 81% !important;
}

.ml82pc {
  margin-left: 82% !important;
}

.ml83pc {
  margin-left: 83% !important;
}

.ml84pc {
  margin-left: 84% !important;
}

.ml85pc {
  margin-left: 85% !important;
}

.ml86pc {
  margin-left: 86% !important;
}

.ml87pc {
  margin-left: 87% !important;
}

.ml88pc {
  margin-left: 88% !important;
}

.ml89pc {
  margin-left: 89% !important;
}

.ml90pc {
  margin-left: 90% !important;
}

.ml91pc {
  margin-left: 91% !important;
}

.ml92pc {
  margin-left: 92% !important;
}

.ml93pc {
  margin-left: 93% !important;
}

.ml94pc {
  margin-left: 94% !important;
}

.ml95pc {
  margin-left: 95% !important;
}

.ml96pc {
  margin-left: 96% !important;
}

.ml97pc {
  margin-left: 97% !important;
}

.ml98pc {
  margin-left: 98% !important;
}

.ml99pc {
  margin-left: 99% !important;
}

.ml100pc {
  margin-left: 100% !important;
}

/*====================================================================================
笆�04.PADDING DEFAULT - PERCENT
====================================================================================*/
/*PADDING*/
.p00pc {
  padding: 0% !important;
}

.p01pc {
  padding: 1% !important;
}

.p02pc {
  padding: 2% !important;
}

.p03pc {
  padding: 3% !important;
}

.p04pc {
  padding: 4% !important;
}

.p05pc {
  padding: 5% !important;
}

.p06pc {
  padding: 6% !important;
}

.p07pc {
  padding: 7% !important;
}

.p08pc {
  padding: 8% !important;
}

.p09pc {
  padding: 9% !important;
}

.p10pc {
  padding: 10% !important;
}

.p11pc {
  padding: 11% !important;
}

.p12pc {
  padding: 12% !important;
}

.p13pc {
  padding: 13% !important;
}

.p14pc {
  padding: 14% !important;
}

.p15pc {
  padding: 15% !important;
}

.p16pc {
  padding: 16% !important;
}

.p17pc {
  padding: 17% !important;
}

.p18pc {
  padding: 18% !important;
}

.p19pc {
  padding: 19% !important;
}

.p20pc {
  padding: 20% !important;
}

.p21pc {
  padding: 21% !important;
}

.p22pc {
  padding: 22% !important;
}

.p23pc {
  padding: 23% !important;
}

.p24pc {
  padding: 24% !important;
}

.p25pc {
  padding: 25% !important;
}

.p26pc {
  padding: 26% !important;
}

.p27pc {
  padding: 27% !important;
}

.p28pc {
  padding: 28% !important;
}

.p29pc {
  padding: 29% !important;
}

.p30pc {
  padding: 30% !important;
}

.p31pc {
  padding: 31% !important;
}

.p32pc {
  padding: 32% !important;
}

.p33pc {
  padding: 33% !important;
}

.p34pc {
  padding: 34% !important;
}

.p35pc {
  padding: 35% !important;
}

.p36pc {
  padding: 36% !important;
}

.p37pc {
  padding: 37% !important;
}

.p38pc {
  padding: 38% !important;
}

.p39pc {
  padding: 39% !important;
}

.p40pc {
  padding: 40% !important;
}

.p41pc {
  padding: 41% !important;
}

.p42pc {
  padding: 42% !important;
}

.p43pc {
  padding: 43% !important;
}

.p44pc {
  padding: 44% !important;
}

.p45pc {
  padding: 45% !important;
}

.p46pc {
  padding: 46% !important;
}

.p47pc {
  padding: 47% !important;
}

.p48pc {
  padding: 48% !important;
}

.p49pc {
  padding: 49% !important;
}

.p50pc {
  padding: 50% !important;
}

.p51pc {
  padding: 51% !important;
}

.p52pc {
  padding: 52% !important;
}

.p53pc {
  padding: 53% !important;
}

.p54pc {
  padding: 54% !important;
}

.p55pc {
  padding: 55% !important;
}

.p56pc {
  padding: 56% !important;
}

.p57pc {
  padding: 57% !important;
}

.p58pc {
  padding: 58% !important;
}

.p59pc {
  padding: 59% !important;
}

.p60pc {
  padding: 60% !important;
}

.p61pc {
  padding: 61% !important;
}

.p62pc {
  padding: 62% !important;
}

.p63pc {
  padding: 63% !important;
}

.p64pc {
  padding: 64% !important;
}

.p65pc {
  padding: 65% !important;
}

.p66pc {
  padding: 66% !important;
}

.p67pc {
  padding: 67% !important;
}

.p68pc {
  padding: 68% !important;
}

.p69pc {
  padding: 69% !important;
}

.p70pc {
  padding: 70% !important;
}

.p71pc {
  padding: 71% !important;
}

.p72pc {
  padding: 72% !important;
}

.p73pc {
  padding: 73% !important;
}

.p74pc {
  padding: 74% !important;
}

.p75pc {
  padding: 75% !important;
}

.p76pc {
  padding: 76% !important;
}

.p77pc {
  padding: 77% !important;
}

.p78pc {
  padding: 78% !important;
}

.p79pc {
  padding: 79% !important;
}

.p80pc {
  padding: 80% !important;
}

.p81pc {
  padding: 81% !important;
}

.p82pc {
  padding: 82% !important;
}

.p83pc {
  padding: 83% !important;
}

.p84pc {
  padding: 84% !important;
}

.p85pc {
  padding: 85% !important;
}

.p86pc {
  padding: 86% !important;
}

.p87pc {
  padding: 87% !important;
}

.p88pc {
  padding: 88% !important;
}

.p89pc {
  padding: 89% !important;
}

.p90pc {
  padding: 90% !important;
}

.p91pc {
  padding: 91% !important;
}

.p92pc {
  padding: 92% !important;
}

.p93pc {
  padding: 93% !important;
}

.p94pc {
  padding: 94% !important;
}

.p95pc {
  padding: 95% !important;
}

.p96pc {
  padding: 96% !important;
}

.p97pc {
  padding: 97% !important;
}

.p98pc {
  padding: 98% !important;
}

.p99pc {
  padding: 99% !important;
}

.p100pc {
  padding: 100% !important;
}

/*PADDING - TOP*/
.pt00pc {
  padding-top: 0% !important;
}

.pt01pc {
  padding-top: 1% !important;
}

.pt02pc {
  padding-top: 2% !important;
}

.pt03pc {
  padding-top: 3% !important;
}

.pt04pc {
  padding-top: 4% !important;
}

.pt05pc {
  padding-top: 5% !important;
}

.pt06pc {
  padding-top: 6% !important;
}

.pt07pc {
  padding-top: 7% !important;
}

.pt08pc {
  padding-top: 8% !important;
}

.pt09pc {
  padding-top: 9% !important;
}

.pt10pc {
  padding-top: 10% !important;
}

.pt11pc {
  padding-top: 11% !important;
}

.pt12pc {
  padding-top: 12% !important;
}

.pt13pc {
  padding-top: 13% !important;
}

.pt14pc {
  padding-top: 14% !important;
}

.pt15pc {
  padding-top: 15% !important;
}

.pt16pc {
  padding-top: 16% !important;
}

.pt17pc {
  padding-top: 17% !important;
}

.pt18pc {
  padding-top: 18% !important;
}

.pt19pc {
  padding-top: 19% !important;
}

.pt20pc {
  padding-top: 20% !important;
}

.pt21pc {
  padding-top: 21% !important;
}

.pt22pc {
  padding-top: 22% !important;
}

.pt23pc {
  padding-top: 23% !important;
}

.pt24pc {
  padding-top: 24% !important;
}

.pt25pc {
  padding-top: 25% !important;
}

.pt26pc {
  padding-top: 26% !important;
}

.pt27pc {
  padding-top: 27% !important;
}

.pt28pc {
  padding-top: 28% !important;
}

.pt29pc {
  padding-top: 29% !important;
}

.pt30pc {
  padding-top: 30% !important;
}

.pt31pc {
  padding-top: 31% !important;
}

.pt32pc {
  padding-top: 32% !important;
}

.pt33pc {
  padding-top: 33% !important;
}

.pt34pc {
  padding-top: 34% !important;
}

.pt35pc {
  padding-top: 35% !important;
}

.pt36pc {
  padding-top: 36% !important;
}

.pt37pc {
  padding-top: 37% !important;
}

.pt38pc {
  padding-top: 38% !important;
}

.pt39pc {
  padding-top: 39% !important;
}

.pt40pc {
  padding-top: 40% !important;
}

.pt41pc {
  padding-top: 41% !important;
}

.pt42pc {
  padding-top: 42% !important;
}

.pt43pc {
  padding-top: 43% !important;
}

.pt44pc {
  padding-top: 44% !important;
}

.pt45pc {
  padding-top: 45% !important;
}

.pt46pc {
  padding-top: 46% !important;
}

.pt47pc {
  padding-top: 47% !important;
}

.pt48pc {
  padding-top: 48% !important;
}

.pt49pc {
  padding-top: 49% !important;
}

.pt50pc {
  padding-top: 50% !important;
}

.pt51pc {
  padding-top: 51% !important;
}

.pt52pc {
  padding-top: 52% !important;
}

.pt53pc {
  padding-top: 53% !important;
}

.pt54pc {
  padding-top: 54% !important;
}

.pt55pc {
  padding-top: 55% !important;
}

.pt56pc {
  padding-top: 56% !important;
}

.pt57pc {
  padding-top: 57% !important;
}

.pt58pc {
  padding-top: 58% !important;
}

.pt59pc {
  padding-top: 59% !important;
}

.pt60pc {
  padding-top: 60% !important;
}

.pt61pc {
  padding-top: 61% !important;
}

.pt62pc {
  padding-top: 62% !important;
}

.pt63pc {
  padding-top: 63% !important;
}

.pt64pc {
  padding-top: 64% !important;
}

.pt65pc {
  padding-top: 65% !important;
}

.pt66pc {
  padding-top: 66% !important;
}

.pt67pc {
  padding-top: 67% !important;
}

.pt68pc {
  padding-top: 68% !important;
}

.pt69pc {
  padding-top: 69% !important;
}

.pt70pc {
  padding-top: 70% !important;
}

.pt71pc {
  padding-top: 71% !important;
}

.pt72pc {
  padding-top: 72% !important;
}

.pt73pc {
  padding-top: 73% !important;
}

.pt74pc {
  padding-top: 74% !important;
}

.pt75pc {
  padding-top: 75% !important;
}

.pt76pc {
  padding-top: 76% !important;
}

.pt77pc {
  padding-top: 77% !important;
}

.pt78pc {
  padding-top: 78% !important;
}

.pt79pc {
  padding-top: 79% !important;
}

.pt80pc {
  padding-top: 80% !important;
}

.pt81pc {
  padding-top: 81% !important;
}

.pt82pc {
  padding-top: 82% !important;
}

.pt83pc {
  padding-top: 83% !important;
}

.pt84pc {
  padding-top: 84% !important;
}

.pt85pc {
  padding-top: 85% !important;
}

.pt86pc {
  padding-top: 86% !important;
}

.pt87pc {
  padding-top: 87% !important;
}

.pt88pc {
  padding-top: 88% !important;
}

.pt89pc {
  padding-top: 89% !important;
}

.pt90pc {
  padding-top: 90% !important;
}

.pt91pc {
  padding-top: 91% !important;
}

.pt92pc {
  padding-top: 92% !important;
}

.pt93pc {
  padding-top: 93% !important;
}

.pt94pc {
  padding-top: 94% !important;
}

.pt95pc {
  padding-top: 95% !important;
}

.pt96pc {
  padding-top: 96% !important;
}

.pt97pc {
  padding-top: 97% !important;
}

.pt98pc {
  padding-top: 98% !important;
}

.pt99pc {
  padding-top: 99% !important;
}

.pt100pc {
  padding-top: 100% !important;
}

/*PADDING - RIGHT*/
.pr00pc {
  padding-right: 0% !important;
}

.pr01pc {
  padding-right: 1% !important;
}

.pr02pc {
  padding-right: 2% !important;
}

.pr03pc {
  padding-right: 3% !important;
}

.pr04pc {
  padding-right: 4% !important;
}

.pr05pc {
  padding-right: 5% !important;
}

.pr06pc {
  padding-right: 6% !important;
}

.pr07pc {
  padding-right: 7% !important;
}

.pr08pc {
  padding-right: 8% !important;
}

.pr09pc {
  padding-right: 9% !important;
}

.pr10pc {
  padding-right: 10% !important;
}

.pr11pc {
  padding-right: 11% !important;
}

.pr12pc {
  padding-right: 12% !important;
}

.pr13pc {
  padding-right: 13% !important;
}

.pr14pc {
  padding-right: 14% !important;
}

.pr15pc {
  padding-right: 15% !important;
}

.pr16pc {
  padding-right: 16% !important;
}

.pr17pc {
  padding-right: 17% !important;
}

.pr18pc {
  padding-right: 18% !important;
}

.pr19pc {
  padding-right: 19% !important;
}

.pr20pc {
  padding-right: 20% !important;
}

.pr21pc {
  padding-right: 21% !important;
}

.pr22pc {
  padding-right: 22% !important;
}

.pr23pc {
  padding-right: 23% !important;
}

.pr24pc {
  padding-right: 24% !important;
}

.pr25pc {
  padding-right: 25% !important;
}

.pr26pc {
  padding-right: 26% !important;
}

.pr27pc {
  padding-right: 27% !important;
}

.pr28pc {
  padding-right: 28% !important;
}

.pr29pc {
  padding-right: 29% !important;
}

.pr30pc {
  padding-right: 30% !important;
}

.pr31pc {
  padding-right: 31% !important;
}

.pr32pc {
  padding-right: 32% !important;
}

.pr33pc {
  padding-right: 33% !important;
}

.pr34pc {
  padding-right: 34% !important;
}

.pr35pc {
  padding-right: 35% !important;
}

.pr36pc {
  padding-right: 36% !important;
}

.pr37pc {
  padding-right: 37% !important;
}

.pr38pc {
  padding-right: 38% !important;
}

.pr39pc {
  padding-right: 39% !important;
}

.pr40pc {
  padding-right: 40% !important;
}

.pr41pc {
  padding-right: 41% !important;
}

.pr42pc {
  padding-right: 42% !important;
}

.pr43pc {
  padding-right: 43% !important;
}

.pr44pc {
  padding-right: 44% !important;
}

.pr45pc {
  padding-right: 45% !important;
}

.pr46pc {
  padding-right: 46% !important;
}

.pr47pc {
  padding-right: 47% !important;
}

.pr48pc {
  padding-right: 48% !important;
}

.pr49pc {
  padding-right: 49% !important;
}

.pr50pc {
  padding-right: 50% !important;
}

.pr51pc {
  padding-right: 51% !important;
}

.pr52pc {
  padding-right: 52% !important;
}

.pr53pc {
  padding-right: 53% !important;
}

.pr54pc {
  padding-right: 54% !important;
}

.pr55pc {
  padding-right: 55% !important;
}

.pr56pc {
  padding-right: 56% !important;
}

.pr57pc {
  padding-right: 57% !important;
}

.pr58pc {
  padding-right: 58% !important;
}

.pr59pc {
  padding-right: 59% !important;
}

.pr60pc {
  padding-right: 60% !important;
}

.pr61pc {
  padding-right: 61% !important;
}

.pr62pc {
  padding-right: 62% !important;
}

.pr63pc {
  padding-right: 63% !important;
}

.pr64pc {
  padding-right: 64% !important;
}

.pr65pc {
  padding-right: 65% !important;
}

.pr66pc {
  padding-right: 66% !important;
}

.pr67pc {
  padding-right: 67% !important;
}

.pr68pc {
  padding-right: 68% !important;
}

.pr69pc {
  padding-right: 69% !important;
}

.pr70pc {
  padding-right: 70% !important;
}

.pr71pc {
  padding-right: 71% !important;
}

.pr72pc {
  padding-right: 72% !important;
}

.pr73pc {
  padding-right: 73% !important;
}

.pr74pc {
  padding-right: 74% !important;
}

.pr75pc {
  padding-right: 75% !important;
}

.pr76pc {
  padding-right: 76% !important;
}

.pr77pc {
  padding-right: 77% !important;
}

.pr78pc {
  padding-right: 78% !important;
}

.pr79pc {
  padding-right: 79% !important;
}

.pr80pc {
  padding-right: 80% !important;
}

.pr81pc {
  padding-right: 81% !important;
}

.pr82pc {
  padding-right: 82% !important;
}

.pr83pc {
  padding-right: 83% !important;
}

.pr84pc {
  padding-right: 84% !important;
}

.pr85pc {
  padding-right: 85% !important;
}

.pr86pc {
  padding-right: 86% !important;
}

.pr87pc {
  padding-right: 87% !important;
}

.pr88pc {
  padding-right: 88% !important;
}

.pr89pc {
  padding-right: 89% !important;
}

.pr90pc {
  padding-right: 90% !important;
}

.pr91pc {
  padding-right: 91% !important;
}

.pr92pc {
  padding-right: 92% !important;
}

.pr93pc {
  padding-right: 93% !important;
}

.pr94pc {
  padding-right: 94% !important;
}

.pr95pc {
  padding-right: 95% !important;
}

.pr96pc {
  padding-right: 96% !important;
}

.pr97pc {
  padding-right: 97% !important;
}

.pr98pc {
  padding-right: 98% !important;
}

.pr99pc {
  padding-right: 99% !important;
}

.pr100pc {
  padding-right: 100% !important;
}

/*PADDING - BOTTOM*/
.pb00pc {
  padding-bottom: 0% !important;
}

.pb01pc {
  padding-bottom: 1% !important;
}

.pb02pc {
  padding-bottom: 2% !important;
}

.pb03pc {
  padding-bottom: 3% !important;
}

.pb04pc {
  padding-bottom: 4% !important;
}

.pb05pc {
  padding-bottom: 5% !important;
}

.pb06pc {
  padding-bottom: 6% !important;
}

.pb07pc {
  padding-bottom: 7% !important;
}

.pb08pc {
  padding-bottom: 8% !important;
}

.pb09pc {
  padding-bottom: 9% !important;
}

.pb10pc {
  padding-bottom: 10% !important;
}

.pb11pc {
  padding-bottom: 11% !important;
}

.pb12pc {
  padding-bottom: 12% !important;
}

.pb13pc {
  padding-bottom: 13% !important;
}

.pb14pc {
  padding-bottom: 14% !important;
}

.pb15pc {
  padding-bottom: 15% !important;
}

.pb16pc {
  padding-bottom: 16% !important;
}

.pb17pc {
  padding-bottom: 17% !important;
}

.pb18pc {
  padding-bottom: 18% !important;
}

.pb19pc {
  padding-bottom: 19% !important;
}

.pb20pc {
  padding-bottom: 20% !important;
}

.pb21pc {
  padding-bottom: 21% !important;
}

.pb22pc {
  padding-bottom: 22% !important;
}

.pb23pc {
  padding-bottom: 23% !important;
}

.pb24pc {
  padding-bottom: 24% !important;
}

.pb25pc {
  padding-bottom: 25% !important;
}

.pb26pc {
  padding-bottom: 26% !important;
}

.pb27pc {
  padding-bottom: 27% !important;
}

.pb28pc {
  padding-bottom: 28% !important;
}

.pb29pc {
  padding-bottom: 29% !important;
}

.pb30pc {
  padding-bottom: 30% !important;
}

.pb31pc {
  padding-bottom: 31% !important;
}

.pb32pc {
  padding-bottom: 32% !important;
}

.pb33pc {
  padding-bottom: 33% !important;
}

.pb34pc {
  padding-bottom: 34% !important;
}

.pb35pc {
  padding-bottom: 35% !important;
}

.pb36pc {
  padding-bottom: 36% !important;
}

.pb37pc {
  padding-bottom: 37% !important;
}

.pb38pc {
  padding-bottom: 38% !important;
}

.pb39pc {
  padding-bottom: 39% !important;
}

.pb40pc {
  padding-bottom: 40% !important;
}

.pb41pc {
  padding-bottom: 41% !important;
}

.pb42pc {
  padding-bottom: 42% !important;
}

.pb43pc {
  padding-bottom: 43% !important;
}

.pb44pc {
  padding-bottom: 44% !important;
}

.pb45pc {
  padding-bottom: 45% !important;
}

.pb46pc {
  padding-bottom: 46% !important;
}

.pb47pc {
  padding-bottom: 47% !important;
}

.pb48pc {
  padding-bottom: 48% !important;
}

.pb49pc {
  padding-bottom: 49% !important;
}

.pb50pc {
  padding-bottom: 50% !important;
}

.pb51pc {
  padding-bottom: 51% !important;
}

.pb52pc {
  padding-bottom: 52% !important;
}

.pb53pc {
  padding-bottom: 53% !important;
}

.pb54pc {
  padding-bottom: 54% !important;
}

.pb55pc {
  padding-bottom: 55% !important;
}

.pb56pc {
  padding-bottom: 56% !important;
}

.pb57pc {
  padding-bottom: 57% !important;
}

.pb58pc {
  padding-bottom: 58% !important;
}

.pb59pc {
  padding-bottom: 59% !important;
}

.pb60pc {
  padding-bottom: 60% !important;
}

.pb61pc {
  padding-bottom: 61% !important;
}

.pb62pc {
  padding-bottom: 62% !important;
}

.pb63pc {
  padding-bottom: 63% !important;
}

.pb64pc {
  padding-bottom: 64% !important;
}

.pb65pc {
  padding-bottom: 65% !important;
}

.pb66pc {
  padding-bottom: 66% !important;
}

.pb67pc {
  padding-bottom: 67% !important;
}

.pb68pc {
  padding-bottom: 68% !important;
}

.pb69pc {
  padding-bottom: 69% !important;
}

.pb70pc {
  padding-bottom: 70% !important;
}

.pb71pc {
  padding-bottom: 71% !important;
}

.pb72pc {
  padding-bottom: 72% !important;
}

.pb73pc {
  padding-bottom: 73% !important;
}

.pb74pc {
  padding-bottom: 74% !important;
}

.pb75pc {
  padding-bottom: 75% !important;
}

.pb76pc {
  padding-bottom: 76% !important;
}

.pb77pc {
  padding-bottom: 77% !important;
}

.pb78pc {
  padding-bottom: 78% !important;
}

.pb79pc {
  padding-bottom: 79% !important;
}

.pb80pc {
  padding-bottom: 80% !important;
}

.pb81pc {
  padding-bottom: 81% !important;
}

.pb82pc {
  padding-bottom: 82% !important;
}

.pb83pc {
  padding-bottom: 83% !important;
}

.pb84pc {
  padding-bottom: 84% !important;
}

.pb85pc {
  padding-bottom: 85% !important;
}

.pb86pc {
  padding-bottom: 86% !important;
}

.pb87pc {
  padding-bottom: 87% !important;
}

.pb88pc {
  padding-bottom: 88% !important;
}

.pb89pc {
  padding-bottom: 89% !important;
}

.pb90pc {
  padding-bottom: 90% !important;
}

.pb91pc {
  padding-bottom: 91% !important;
}

.pb92pc {
  padding-bottom: 92% !important;
}

.pb93pc {
  padding-bottom: 93% !important;
}

.pb94pc {
  padding-bottom: 94% !important;
}

.pb95pc {
  padding-bottom: 95% !important;
}

.pb96pc {
  padding-bottom: 96% !important;
}

.pb97pc {
  padding-bottom: 97% !important;
}

.pb98pc {
  padding-bottom: 98% !important;
}

.pb99pc {
  padding-bottom: 99% !important;
}

.pb100pc {
  padding-bottom: 100% !important;
}

/*PADDING - LEFT*/
.pl0pc {
  padding-left: 0% !important;
}

.pl1pc {
  padding-left: 1% !important;
}

.pl2pc {
  padding-left: 2% !important;
}

.pl3pc {
  padding-left: 3% !important;
}

.pl4pc {
  padding-left: 4% !important;
}

.pl5pc {
  padding-left: 5% !important;
}

.pl6pc {
  padding-left: 6% !important;
}

.pl7pc {
  padding-left: 7% !important;
}

.pl8pc {
  padding-left: 8% !important;
}

.pl9pc {
  padding-left: 9% !important;
}

.pl10pc {
  padding-left: 10% !important;
}

.pl11pc {
  padding-left: 11% !important;
}

.pl12pc {
  padding-left: 12% !important;
}

.pl13pc {
  padding-left: 13% !important;
}

.pl14pc {
  padding-left: 14% !important;
}

.pl15pc {
  padding-left: 15% !important;
}

.pl16pc {
  padding-left: 16% !important;
}

.pl17pc {
  padding-left: 17% !important;
}

.pl18pc {
  padding-left: 18% !important;
}

.pl19pc {
  padding-left: 19% !important;
}

.pl20pc {
  padding-left: 20% !important;
}

.pl21pc {
  padding-left: 21% !important;
}

.pl22pc {
  padding-left: 22% !important;
}

.pl23pc {
  padding-left: 23% !important;
}

.pl24pc {
  padding-left: 24% !important;
}

.pl25pc {
  padding-left: 25% !important;
}

.pl26pc {
  padding-left: 26% !important;
}

.pl27pc {
  padding-left: 27% !important;
}

.pl28pc {
  padding-left: 28% !important;
}

.pl29pc {
  padding-left: 29% !important;
}

.pl30pc {
  padding-left: 30% !important;
}

.pl31pc {
  padding-left: 31% !important;
}

.pl32pc {
  padding-left: 32% !important;
}

.pl33pc {
  padding-left: 33% !important;
}

.pl34pc {
  padding-left: 34% !important;
}

.pl35pc {
  padding-left: 35% !important;
}

.pl36pc {
  padding-left: 36% !important;
}

.pl37pc {
  padding-left: 37% !important;
}

.pl38pc {
  padding-left: 38% !important;
}

.pl39pc {
  padding-left: 39% !important;
}

.pl40pc {
  padding-left: 40% !important;
}

.pl41pc {
  padding-left: 41% !important;
}

.pl42pc {
  padding-left: 42% !important;
}

.pl43pc {
  padding-left: 43% !important;
}

.pl44pc {
  padding-left: 44% !important;
}

.pl45pc {
  padding-left: 45% !important;
}

.pl46pc {
  padding-left: 46% !important;
}

.pl47pc {
  padding-left: 47% !important;
}

.pl48pc {
  padding-left: 48% !important;
}

.pl49pc {
  padding-left: 49% !important;
}

.pl50pc {
  padding-left: 50% !important;
}

.pl51pc {
  padding-left: 51% !important;
}

.pl52pc {
  padding-left: 52% !important;
}

.pl53pc {
  padding-left: 53% !important;
}

.pl54pc {
  padding-left: 54% !important;
}

.pl55pc {
  padding-left: 55% !important;
}

.pl56pc {
  padding-left: 56% !important;
}

.pl57pc {
  padding-left: 57% !important;
}

.pl58pc {
  padding-left: 58% !important;
}

.pl59pc {
  padding-left: 59% !important;
}

.pl60pc {
  padding-left: 60% !important;
}

.pl61pc {
  padding-left: 61% !important;
}

.pl62pc {
  padding-left: 62% !important;
}

.pl63pc {
  padding-left: 63% !important;
}

.pl64pc {
  padding-left: 64% !important;
}

.pl65pc {
  padding-left: 65% !important;
}

.pl66pc {
  padding-left: 66% !important;
}

.pl67pc {
  padding-left: 67% !important;
}

.pl68pc {
  padding-left: 68% !important;
}

.pl69pc {
  padding-left: 69% !important;
}

.pl70pc {
  padding-left: 70% !important;
}

.pl71pc {
  padding-left: 71% !important;
}

.pl72pc {
  padding-left: 72% !important;
}

.pl73pc {
  padding-left: 73% !important;
}

.pl74pc {
  padding-left: 74% !important;
}

.pl75pc {
  padding-left: 75% !important;
}

.pl76pc {
  padding-left: 76% !important;
}

.pl77pc {
  padding-left: 77% !important;
}

.pl78pc {
  padding-left: 78% !important;
}

.pl79pc {
  padding-left: 79% !important;
}

.pl80pc {
  padding-left: 80% !important;
}

.pl81pc {
  padding-left: 81% !important;
}

.pl82pc {
  padding-left: 82% !important;
}

.pl83pc {
  padding-left: 83% !important;
}

.pl84pc {
  padding-left: 84% !important;
}

.pl85pc {
  padding-left: 85% !important;
}

.pl86pc {
  padding-left: 86% !important;
}

.pl87pc {
  padding-left: 87% !important;
}

.pl88pc {
  padding-left: 88% !important;
}

.pl89pc {
  padding-left: 89% !important;
}

.pl90pc {
  padding-left: 90% !important;
}

.pl91pc {
  padding-left: 91% !important;
}

.pl92pc {
  padding-left: 92% !important;
}

.pl93pc {
  padding-left: 93% !important;
}

.pl94pc {
  padding-left: 94% !important;
}

.pl95pc {
  padding-left: 95% !important;
}

.pl96pc {
  padding-left: 96% !important;
}

.pl97pc {
  padding-left: 97% !important;
}

.pl98pc {
  padding-left: 98% !important;
}

.pl99pc {
  padding-left: 99% !important;
}

.pl100pc {
  padding-left: 100% !important;
}

/*====================================================================================
笆�05.w DEFAULT - PIXEL
====================================================================================*/
.w00 {
  width: 0px !important;
}

.w01 {
  width: 1px !important;
}

.w02 {
  width: 2px !important;
}

.w03 {
  width: 3px !important;
}

.w04 {
  width: 4px !important;
}

.w05 {
  width: 5px !important;
}

.w06 {
  width: 6px !important;
}

.w07 {
  width: 7px !important;
}

.w08 {
  width: 8px !important;
}

.w09 {
  width: 9px !important;
}

.w10 {
  width: 10px !important;
}

.w11 {
  width: 11px !important;
}

.w12 {
  width: 12px !important;
}

.w13 {
  width: 13px !important;
}

.w14 {
  width: 14px !important;
}

.w15 {
  width: 15px !important;
}

.w16 {
  width: 16px !important;
}

.w17 {
  width: 17px !important;
}

.w18 {
  width: 18px !important;
}

.w19 {
  width: 19px !important;
}

.w20 {
  width: 20px !important;
}

.w21 {
  width: 21px !important;
}

.w22 {
  width: 22px !important;
}

.w23 {
  width: 23px !important;
}

.w24 {
  width: 24px !important;
}

.w25 {
  width: 25px !important;
}

.w26 {
  width: 26px !important;
}

.w27 {
  width: 27px !important;
}

.w28 {
  width: 28px !important;
}

.w29 {
  width: 29px !important;
}

.w30 {
  width: 30px !important;
}

.w31 {
  width: 31px !important;
}

.w32 {
  width: 32px !important;
}

.w33 {
  width: 33px !important;
}

.w34 {
  width: 34px !important;
}

.w35 {
  width: 35px !important;
}

.w36 {
  width: 36px !important;
}

.w37 {
  width: 37px !important;
}

.w38 {
  width: 38px !important;
}

.w39 {
  width: 39px !important;
}

.w40 {
  width: 40px !important;
}

.w41 {
  width: 41px !important;
}

.w42 {
  width: 42px !important;
}

.w43 {
  width: 43px !important;
}

.w44 {
  width: 44px !important;
}

.w45 {
  width: 45px !important;
}

.w46 {
  width: 46px !important;
}

.w47 {
  width: 47px !important;
}

.w48 {
  width: 48px !important;
}

.w49 {
  width: 49px !important;
}

.w50 {
  width: 50px !important;
}

.w51 {
  width: 51px !important;
}

.w52 {
  width: 52px !important;
}

.w53 {
  width: 53px !important;
}

.w54 {
  width: 54px !important;
}

.w55 {
  width: 55px !important;
}

.w56 {
  width: 56px !important;
}

.w57 {
  width: 57px !important;
}

.w58 {
  width: 58px !important;
}

.w59 {
  width: 59px !important;
}

.w60 {
  width: 60px !important;
}

.w61 {
  width: 61px !important;
}

.w62 {
  width: 62px !important;
}

.w63 {
  width: 63px !important;
}

.w64 {
  width: 64px !important;
}

.w65 {
  width: 65px !important;
}

.w66 {
  width: 66px !important;
}

.w67 {
  width: 67px !important;
}

.w68 {
  width: 68px !important;
}

.w69 {
  width: 69px !important;
}

.w70 {
  width: 70px !important;
}

.w71 {
  width: 71px !important;
}

.w72 {
  width: 72px !important;
}

.w73 {
  width: 73px !important;
}

.w74 {
  width: 74px !important;
}

.w75 {
  width: 75px !important;
}

.w76 {
  width: 76px !important;
}

.w77 {
  width: 77px !important;
}

.w78 {
  width: 78px !important;
}

.w79 {
  width: 79px !important;
}

.w80 {
  width: 80px !important;
}

.w81 {
  width: 81px !important;
}

.w82 {
  width: 82px !important;
}

.w83 {
  width: 83px !important;
}

.w84 {
  width: 84px !important;
}

.w85 {
  width: 85px !important;
}

.w86 {
  width: 86px !important;
}

.w87 {
  width: 87px !important;
}

.w88 {
  width: 88px !important;
}

.w89 {
  width: 89px !important;
}

.w90 {
  width: 90px !important;
}

.w91 {
  width: 91px !important;
}

.w92 {
  width: 92px !important;
}

.w93 {
  width: 93px !important;
}

.w94 {
  width: 94px !important;
}

.w95 {
  width: 95px !important;
}

.w96 {
  width: 96px !important;
}

.w97 {
  width: 97px !important;
}

.w98 {
  width: 98px !important;
}

.w99 {
  width: 99px !important;
}

.w100 {
  width: 100px !important;
}

/*====================================================================================
笆�06.w DEFAULT - PERCENT
====================================================================================*/
.w00pc {
  width: 0% !important;
}

.w01pc {
  width: 1% !important;
}

.w02pc {
  width: 2% !important;
}

.w03pc {
  width: 3% !important;
}

.w04pc {
  width: 4% !important;
}

.w05pc {
  width: 5% !important;
}

.w06pc {
  width: 6% !important;
}

.w07pc {
  width: 7% !important;
}

.w08pc {
  width: 8% !important;
}

.w09pc {
  width: 9% !important;
}

.w10pc {
  width: 10% !important;
}

.w11pc {
  width: 11% !important;
}

.w12pc {
  width: 12% !important;
}

.w13pc {
  width: 13% !important;
}

.w14pc {
  width: 14% !important;
}

.w15pc {
  width: 15% !important;
}

.w16pc {
  width: 16% !important;
}

.w17pc {
  width: 17% !important;
}

.w18pc {
  width: 18% !important;
}

.w19pc {
  width: 19% !important;
}

.w20pc {
  width: 20% !important;
}

.w21pc {
  width: 21% !important;
}

.w22pc {
  width: 22% !important;
}

.w23pc {
  width: 23% !important;
}

.w24pc {
  width: 24% !important;
}

.w25pc {
  width: 25% !important;
}

.w26pc {
  width: 26% !important;
}

.w27pc {
  width: 27% !important;
}

.w28pc {
  width: 28% !important;
}

.w29pc {
  width: 29% !important;
}

.w30pc {
  width: 30% !important;
}

.w31pc {
  width: 31% !important;
}

.w32pc {
  width: 32% !important;
}

.w33pc {
  width: 33% !important;
}

.w34pc {
  width: 34% !important;
}

.w35pc {
  width: 35% !important;
}

.w36pc {
  width: 36% !important;
}

.w37pc {
  width: 37% !important;
}

.w38pc {
  width: 38% !important;
}

.w39pc {
  width: 39% !important;
}

.w40pc {
  width: 40% !important;
}

.w41pc {
  width: 41% !important;
}

.w42pc {
  width: 42% !important;
}

.w43pc {
  width: 43% !important;
}

.w44pc {
  width: 44% !important;
}

.w45pc {
  width: 45% !important;
}

.w46pc {
  width: 46% !important;
}

.w47pc {
  width: 47% !important;
}

.w48pc {
  width: 48% !important;
}

.w49pc {
  width: 49% !important;
}

.w50pc {
  width: 50% !important;
}

.w51pc {
  width: 51% !important;
}

.w52pc {
  width: 52% !important;
}

.w53pc {
  width: 53% !important;
}

.w54pc {
  width: 54% !important;
}

.w55pc {
  width: 55% !important;
}

.w56pc {
  width: 56% !important;
}

.w57pc {
  width: 57% !important;
}

.w58pc {
  width: 58% !important;
}

.w59pc {
  width: 59% !important;
}

.w60pc {
  width: 60% !important;
}

.w61pc {
  width: 61% !important;
}

.w62pc {
  width: 62% !important;
}

.w63pc {
  width: 63% !important;
}

.w64pc {
  width: 64% !important;
}

.w65pc {
  width: 65% !important;
}

.w66pc {
  width: 66% !important;
}

.w67pc {
  width: 67% !important;
}

.w68pc {
  width: 68% !important;
}

.w69pc {
  width: 69% !important;
}

.w70pc {
  width: 70% !important;
}

.w71pc {
  width: 71% !important;
}

.w72pc {
  width: 72% !important;
}

.w73pc {
  width: 73% !important;
}

.w74pc {
  width: 74% !important;
}

.w75pc {
  width: 75% !important;
}

.w76pc {
  width: 76% !important;
}

.w77pc {
  width: 77% !important;
}

.w78pc {
  width: 78% !important;
}

.w79pc {
  width: 79% !important;
}

.w80pc {
  width: 80% !important;
}

.w81pc {
  width: 81% !important;
}

.w82pc {
  width: 82% !important;
}

.w83pc {
  width: 83% !important;
}

.w84pc {
  width: 84% !important;
}

.w85pc {
  width: 85% !important;
}

.w86pc {
  width: 86% !important;
}

.w87pc {
  width: 87% !important;
}

.w88pc {
  width: 88% !important;
}

.w89pc {
  width: 89% !important;
}

.w90pc {
  width: 90% !important;
}

.w91pc {
  width: 91% !important;
}

.w92pc {
  width: 92% !important;
}

.w93pc {
  width: 93% !important;
}

.w94pc {
  width: 94% !important;
}

.w95pc {
  width: 95% !important;
}

.w96pc {
  width: 96% !important;
}

.w97pc {
  width: 97% !important;
}

.w98pc {
  width: 98% !important;
}

.w99pc {
  width: 99% !important;
}

.w100pc {
  width: 100% !important;
}

/*====================================================================================
笆�07.HEIGHT DEFAULT - PIXEL
====================================================================================*/
.h00 {
  height: 0px !important;
}

.h01 {
  height: 1px !important;
}

.h02 {
  height: 2px !important;
}

.h03 {
  height: 3px !important;
}

.h04 {
  height: 4px !important;
}

.h05 {
  height: 5px !important;
}

.h06 {
  height: 6px !important;
}

.h07 {
  height: 7px !important;
}

.h08 {
  height: 8px !important;
}

.h09 {
  height: 9px !important;
}

.h10 {
  height: 10px !important;
}

.h11 {
  height: 11px !important;
}

.h12 {
  height: 12px !important;
}

.h13 {
  height: 13px !important;
}

.h14 {
  height: 14px !important;
}

.h15 {
  height: 15px !important;
}

.h16 {
  height: 16px !important;
}

.h17 {
  height: 17px !important;
}

.h18 {
  height: 18px !important;
}

.h19 {
  height: 19px !important;
}

.h20 {
  height: 20px !important;
}

.h21 {
  height: 21px !important;
}

.h22 {
  height: 22px !important;
}

.h23 {
  height: 23px !important;
}

.h24 {
  height: 24px !important;
}

.h25 {
  height: 25px !important;
}

.h26 {
  height: 26px !important;
}

.h27 {
  height: 27px !important;
}

.h28 {
  height: 28px !important;
}

.h29 {
  height: 29px !important;
}

.h30 {
  height: 30px !important;
}

.h31 {
  height: 31px !important;
}

.h32 {
  height: 32px !important;
}

.h33 {
  height: 33px !important;
}

.h34 {
  height: 34px !important;
}

.h35 {
  height: 35px !important;
}

.h36 {
  height: 36px !important;
}

.h37 {
  height: 37px !important;
}

.h38 {
  height: 38px !important;
}

.h39 {
  height: 39px !important;
}

.h40 {
  height: 40px !important;
}

.h41 {
  height: 41px !important;
}

.h42 {
  height: 42px !important;
}

.h43 {
  height: 43px !important;
}

.h44 {
  height: 44px !important;
}

.h45 {
  height: 45px !important;
}

.h46 {
  height: 46px !important;
}

.h47 {
  height: 47px !important;
}

.h48 {
  height: 48px !important;
}

.h49 {
  height: 49px !important;
}

.h50 {
  height: 50px !important;
}

.h51 {
  height: 51px !important;
}

.h52 {
  height: 52px !important;
}

.h53 {
  height: 53px !important;
}

.h54 {
  height: 54px !important;
}

.h55 {
  height: 55px !important;
}

.h56 {
  height: 56px !important;
}

.h57 {
  height: 57px !important;
}

.h58 {
  height: 58px !important;
}

.h59 {
  height: 59px !important;
}

.h60 {
  height: 60px !important;
}

.h61 {
  height: 61px !important;
}

.h62 {
  height: 62px !important;
}

.h63 {
  height: 63px !important;
}

.h64 {
  height: 64px !important;
}

.h65 {
  height: 65px !important;
}

.h66 {
  height: 66px !important;
}

.h67 {
  height: 67px !important;
}

.h68 {
  height: 68px !important;
}

.h69 {
  height: 69px !important;
}

.h70 {
  height: 70px !important;
}

.h71 {
  height: 71px !important;
}

.h72 {
  height: 72px !important;
}

.h73 {
  height: 73px !important;
}

.h74 {
  height: 74px !important;
}

.h75 {
  height: 75px !important;
}

.h76 {
  height: 76px !important;
}

.h77 {
  height: 77px !important;
}

.h78 {
  height: 78px !important;
}

.h79 {
  height: 79px !important;
}

.h80 {
  height: 80px !important;
}

.h81 {
  height: 81px !important;
}

.h82 {
  height: 82px !important;
}

.h83 {
  height: 83px !important;
}

.h84 {
  height: 84px !important;
}

.h85 {
  height: 85px !important;
}

.h86 {
  height: 86px !important;
}

.h87 {
  height: 87px !important;
}

.h88 {
  height: 88px !important;
}

.h89 {
  height: 89px !important;
}

.h90 {
  height: 90px !important;
}

.h91 {
  height: 91px !important;
}

.h92 {
  height: 92px !important;
}

.h93 {
  height: 93px !important;
}

.h94 {
  height: 94px !important;
}

.h95 {
  height: 95px !important;
}

.h96 {
  height: 96px !important;
}

.h97 {
  height: 97px !important;
}

.h98 {
  height: 98px !important;
}

.h99 {
  height: 99px !important;
}

/*====================================================================================
笆�08.HEIGHT DEFAULT - PERCENT
====================================================================================*/
.h00pc {
  height: 0% !important;
}

.h01pc {
  height: 1% !important;
}

.h02pc {
  height: 2% !important;
}

.h03pc {
  height: 3% !important;
}

.h04pc {
  height: 4% !important;
}

.h05pc {
  height: 5% !important;
}

.h06pc {
  height: 6% !important;
}

.h07pc {
  height: 7% !important;
}

.h08pc {
  height: 8% !important;
}

.h09pc {
  height: 9% !important;
}

.h10pc {
  height: 10% !important;
}

.h11pc {
  height: 11% !important;
}

.h12pc {
  height: 12% !important;
}

.h13pc {
  height: 13% !important;
}

.h14pc {
  height: 14% !important;
}

.h15pc {
  height: 15% !important;
}

.h16pc {
  height: 16% !important;
}

.h17pc {
  height: 17% !important;
}

.h18pc {
  height: 18% !important;
}

.h19pc {
  height: 19% !important;
}

.h20pc {
  height: 20% !important;
}

.h21pc {
  height: 21% !important;
}

.h22pc {
  height: 22% !important;
}

.h23pc {
  height: 23% !important;
}

.h24pc {
  height: 24% !important;
}

.h25pc {
  height: 25% !important;
}

.h26pc {
  height: 26% !important;
}

.h27pc {
  height: 27% !important;
}

.h28pc {
  height: 28% !important;
}

.h29pc {
  height: 29% !important;
}

.h30pc {
  height: 30% !important;
}

.h31pc {
  height: 31% !important;
}

.h32pc {
  height: 32% !important;
}

.h33pc {
  height: 33% !important;
}

.h34pc {
  height: 34% !important;
}

.h35pc {
  height: 35% !important;
}

.h36pc {
  height: 36% !important;
}

.h37pc {
  height: 37% !important;
}

.h38pc {
  height: 38% !important;
}

.h39pc {
  height: 39% !important;
}

.h40pc {
  height: 40% !important;
}

.h41pc {
  height: 41% !important;
}

.h42pc {
  height: 42% !important;
}

.h43pc {
  height: 43% !important;
}

.h44pc {
  height: 44% !important;
}

.h45pc {
  height: 45% !important;
}

.h46pc {
  height: 46% !important;
}

.h47pc {
  height: 47% !important;
}

.h48pc {
  height: 48% !important;
}

.h49pc {
  height: 49% !important;
}

.h50pc {
  height: 50% !important;
}

.h51pc {
  height: 51% !important;
}

.h52pc {
  height: 52% !important;
}

.h53pc {
  height: 53% !important;
}

.h54pc {
  height: 54% !important;
}

.h55pc {
  height: 55% !important;
}

.h56pc {
  height: 56% !important;
}

.h57pc {
  height: 57% !important;
}

.h58pc {
  height: 58% !important;
}

.h59pc {
  height: 59% !important;
}

.h60pc {
  height: 60% !important;
}

.h61pc {
  height: 61% !important;
}

.h62pc {
  height: 62% !important;
}

.h63pc {
  height: 63% !important;
}

.h64pc {
  height: 64% !important;
}

.h65pc {
  height: 65% !important;
}

.h66pc {
  height: 66% !important;
}

.h67pc {
  height: 67% !important;
}

.h68pc {
  height: 68% !important;
}

.h69pc {
  height: 69% !important;
}

.h70pc {
  height: 70% !important;
}

.h71pc {
  height: 71% !important;
}

.h72pc {
  height: 72% !important;
}

.h73pc {
  height: 73% !important;
}

.h74pc {
  height: 74% !important;
}

.h75pc {
  height: 75% !important;
}

.h76pc {
  height: 76% !important;
}

.h77pc {
  height: 77% !important;
}

.h78pc {
  height: 78% !important;
}

.h79pc {
  height: 79% !important;
}

.h80pc {
  height: 80% !important;
}

.h81pc {
  height: 81% !important;
}

.h82pc {
  height: 82% !important;
}

.h83pc {
  height: 83% !important;
}

.h84pc {
  height: 84% !important;
}

.h85pc {
  height: 85% !important;
}

.h86pc {
  height: 86% !important;
}

.h87pc {
  height: 87% !important;
}

.h88pc {
  height: 88% !important;
}

.h89pc {
  height: 89% !important;
}

.h90pc {
  height: 90% !important;
}

.h91pc {
  height: 91% !important;
}

.h92pc {
  height: 92% !important;
}

.h93pc {
  height: 93% !important;
}

.h94pc {
  height: 94% !important;
}

.h95pc {
  height: 95% !important;
}

.h96pc {
  height: 96% !important;
}

.h97pc {
  height: 97% !important;
}

.h98pc {
  height: 98% !important;
}

.h99pc {
  height: 99% !important;
}

.h100pc {
  height: 100% !important;
}

/*====================================================================================
笆�09.f-SIZE
====================================================================================*/
.f00 {
  font-size: 0px !important;
}

.f01 {
  font-size: 1px !important;
}

.f02 {
  font-size: 2px !important;
}

.f03 {
  font-size: 3px !important;
}

.f04 {
  font-size: 4px !important;
}

.f05 {
  font-size: 5px !important;
}

.f06 {
  font-size: 6px !important;
}

.f07 {
  font-size: 7px !important;
}

.f08 {
  font-size: 8px !important;
}

.f09 {
  font-size: 9px !important;
}

.f10 {
  font-size: 10px !important;
}

.f11 {
  font-size: 11px !important;
}

.f12 {
  font-size: 12px !important;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f15 {
  font-size: 15px !important;
}

.f16 {
  font-size: 16px !important;
}

.f17 {
  font-size: 17px !important;
}

.f18 {
  font-size: 18px !important;
}

.f19 {
  font-size: 19px !important;
}

.f20 {
  font-size: 20px !important;
}

.f21 {
  font-size: 21px !important;
}

.f22 {
  font-size: 22px !important;
}

.f23 {
  font-size: 23px !important;
}

.f24 {
  font-size: 24px !important;
}

.f25 {
  font-size: 25px !important;
}

.f26 {
  font-size: 26px !important;
}

.f27 {
  font-size: 27px !important;
}

.f28 {
  font-size: 28px !important;
}

.f29 {
  font-size: 29px !important;
}

.f30 {
  font-size: 30px !important;
}

.f31 {
  font-size: 31px !important;
}

.f32 {
  font-size: 32px !important;
}

.f33 {
  font-size: 33px !important;
}

.f34 {
  font-size: 34px !important;
}

.f35 {
  font-size: 35px !important;
}

.f36 {
  font-size: 36px !important;
}

.f37 {
  font-size: 37px !important;
}

.f38 {
  font-size: 38px !important;
}

.f39 {
  font-size: 39px !important;
}

.f40 {
  font-size: 40px !important;
}

.f41 {
  font-size: 41px !important;
}

.f42 {
  font-size: 42px !important;
}

.f43 {
  font-size: 43px !important;
}

.f44 {
  font-size: 44px !important;
}

.f45 {
  font-size: 45px !important;
}

.f46 {
  font-size: 46px !important;
}

.f47 {
  font-size: 47px !important;
}

.f48 {
  font-size: 48px !important;
}

.f49 {
  font-size: 49px !important;
}

.f50 {
  font-size: 50px !important;
}

.f51 {
  font-size: 51px !important;
}

.f52 {
  font-size: 52px !important;
}

.f53 {
  font-size: 53px !important;
}

.f54 {
  font-size: 54px !important;
}

.f55 {
  font-size: 55px !important;
}

/*====================================================================================
笆�10.BORDER-RADIUS
====================================================================================*/
.bdr_rds00 {
  border-radius: 0px !important;
}

.bdr_rds01 {
  border-radius: 1px !important;
}

.bdr_rds02 {
  border-radius: 2px !important;
}

.bdr_rds03 {
  border-radius: 3px !important;
}

.bdr_rds04 {
  border-radius: 4px !important;
}

.bdr_rds05 {
  border-radius: 5px !important;
}

.bdr_rds06 {
  border-radius: 6px !important;
}

.bdr_rds07 {
  border-radius: 7px !important;
}

.bdr_rds08 {
  border-radius: 8px !important;
}

.bdr_rds09 {
  border-radius: 9px !important;
}

.bdr_rds10 {
  border-radius: 10px !important;
}

.bdr_rds11 {
  border-radius: 11px !important;
}

.bdr_rds12 {
  border-radius: 12px !important;
}

.bdr_rds13 {
  border-radius: 13px !important;
}

.bdr_rds14 {
  border-radius: 14px !important;
}

.bdr_rds15 {
  border-radius: 15px !important;
}

.bdr_rds16 {
  border-radius: 16px !important;
}

.bdr_rds17 {
  border-radius: 17px !important;
}

.bdr_rds18 {
  border-radius: 18px !important;
}

.bdr_rds19 {
  border-radius: 19px !important;
}

.bdr_rds20 {
  border-radius: 20px !important;
}

.bdr_rds21 {
  border-radius: 21px !important;
}

.bdr_rds22 {
  border-radius: 22px !important;
}

.bdr_rds23 {
  border-radius: 23px !important;
}

.bdr_rds24 {
  border-radius: 24px !important;
}

.bdr_rds25 {
  border-radius: 25px !important;
}

.bdr_rds26 {
  border-radius: 26px !important;
}

.bdr_rds27 {
  border-radius: 27px !important;
}

.bdr_rds28 {
  border-radius: 28px !important;
}

.bdr_rds29 {
  border-radius: 29px !important;
}

.bdr_rds30 {
  border-radius: 30px !important;
}

.bdr_rds31 {
  border-radius: 31px !important;
}

.bdr_rds32 {
  border-radius: 32px !important;
}

.bdr_rds33 {
  border-radius: 33px !important;
}

.bdr_rds34 {
  border-radius: 34px !important;
}

.bdr_rds35 {
  border-radius: 35px !important;
}

.bdr_rds36 {
  border-radius: 36px !important;
}

.bdr_rds37 {
  border-radius: 37px !important;
}

.bdr_rds38 {
  border-radius: 38px !important;
}

.bdr_rds39 {
  border-radius: 39px !important;
}

.bdr_rds40 {
  border-radius: 40px !important;
}

.bdr_rds41 {
  border-radius: 41px !important;
}

.bdr_rds42 {
  border-radius: 42px !important;
}

.bdr_rds43 {
  border-radius: 43px !important;
}

.bdr_rds44 {
  border-radius: 44px !important;
}

.bdr_rds45 {
  border-radius: 45px !important;
}

.bdr_rds46 {
  border-radius: 46px !important;
}

.bdr_rds47 {
  border-radius: 47px !important;
}

.bdr_rds48 {
  border-radius: 48px !important;
}

.bdr_rds49 {
  border-radius: 49px !important;
}

.bdr_rds50 {
  border-radius: 50px !important;
}

/*====================================================================================
笆�11.LETTER SPACING
====================================================================================*/
.letter_spc0 {
  letter-spacing: 0px !important;
}

.letter_spc1 {
  letter-spacing: 1px !important;
}

.letter_spc2 {
  letter-spacing: 2px !important;
}

.letter_spc3 {
  letter-spacing: 3px !important;
}

.letter_spc4 {
  letter-spacing: 4px !important;
}

.letter_spc5 {
  letter-spacing: 5px !important;
}

.letter_spc6 {
  letter-spacing: 6px !important;
}

.letter_spc7 {
  letter-spacing: 7px !important;
}

.letter_spc8 {
  letter-spacing: 8px !important;
}

.letter_spc9 {
  letter-spacing: 9px !important;
}

.letter_spc10 {
  letter-spacing: 10px !important;
}

/*====================================================================================
笆�12.LETTER SPACING - NEGATIVE
====================================================================================*/
.letter_spc_negative00 {
  letter-spacing: 0px !important;
}

.letter_spc_negative01 {
  letter-spacing: -1px !important;
}

.letter_spc_negative02 {
  letter-spacing: -2px !important;
}

.letter_spc_negative03 {
  letter-spacing: -3px !important;
}

.letter_spc_negative04 {
  letter-spacing: -4px !important;
}

.letter_spc_negative05 {
  letter-spacing: -5px !important;
}

.letter_spc_negative06 {
  letter-spacing: -6px !important;
}

.letter_spc_negative07 {
  letter-spacing: -7px !important;
}

.letter_spc_negative08 {
  letter-spacing: -8px !important;
}

.letter_spc_negative09 {
  letter-spacing: -9px !important;
}

.letter_spc_negative10 {
  letter-spacing: -10px !important;
}

/*====================================================================================
笆�13.LINE-HEIGHT
====================================================================================*/
.lh00 {
  line-height: 0px !important;
}

.lh01 {
  line-height: 0.1px !important;
}

.lh02 {
  line-height: 0.2px !important;
}

.lh03 {
  line-height: 0.3px !important;
}

.lh04 {
  line-height: 0.4px !important;
}

.lh05 {
  line-height: 0.5px !important;
}

.lh06 {
  line-height: 0.6px !important;
}

.lh07 {
  line-height: 0.7px !important;
}

.lh08 {
  line-height: 0.8px !important;
}

.lh09 {
  line-height: 0.9px !important;
}

.lh10 {
  line-height: 1.0px !important;
}

.lh11 {
  line-height: 1.1px !important;
}

.lh12 {
  line-height: 1.2px !important;
}

.lh13 {
  line-height: 1.3px !important;
}

.lh14 {
  line-height: 1.4px !important;
}

.lh15 {
  line-height: 1.5px !important;
}

.lh16 {
  line-height: 1.6px !important;
}

.lh17 {
  line-height: 1.7px !important;
}

.lh18 {
  line-height: 1.8px !important;
}

.lh19 {
  line-height: 1.9px !important;
}

.lh20 {
  line-height: 2.0px !important;
}

.lh21 {
  line-height: 2.1px !important;
}

.lh22 {
  line-height: 2.2px !important;
}

.lh23 {
  line-height: 2.3px !important;
}

.lh24 {
  line-height: 2.4px !important;
}

.lh25 {
  line-height: 2.5px !important;
}

.lh26 {
  line-height: 2.6px !important;
}

.lh27 {
  line-height: 2.7px !important;
}

.lh28 {
  line-height: 2.8px !important;
}

.lh29 {
  line-height: 2.9px !important;
}

.lh30 {
  line-height: 3.0px !important;
}

.lh31 {
  line-height: 3.1px !important;
}

.lh32 {
  line-height: 3.2px !important;
}

.lh33 {
  line-height: 3.3px !important;
}

.lh34 {
  line-height: 3.4px !important;
}

.lh35 {
  line-height: 3.5px !important;
}

.lh36 {
  line-height: 3.6px !important;
}

.lh37 {
  line-height: 3.7px !important;
}

.lh38 {
  line-height: 3.8px !important;
}

.lh39 {
  line-height: 3.9px !important;
}

.lh40 {
  line-height: 4.0px !important;
}

.lh41 {
  line-height: 4.1px !important;
}

.lh42 {
  line-height: 4.2px !important;
}

.lh43 {
  line-height: 4.3px !important;
}

.lh44 {
  line-height: 4.4px !important;
}

.lh45 {
  line-height: 4.5px !important;
}

.lh46 {
  line-height: 4.6px !important;
}

.lh47 {
  line-height: 4.7px !important;
}

.lh48 {
  line-height: 4.8px !important;
}

.lh49 {
  line-height: 4.9px !important;
}

.lh50 {
  line-height: 5.0px !important;
}

/*====================================================================================
笆�14.OTHER
====================================================================================*/
.text_normal {
  font-weight: normal !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_left {
  text-align: left !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.text_under {
  text-decoration: underline !important;
}

.text_n-under {
  text-decoration: none !important;
}

.bg_none {
  background: none !important;
}

.bg_fff {
  background: #FFF !important;
}

.bg_000 {
  background: #000 !important;
}

.co_fff {
  color: #fff !important;
}

.co_000 {
  color: #000 !important;
}

.co_333 {
  color: #333 !important;
}

.border_none {
  border: none !important;
}

.bor_top_none {
  border-top: none !important;
}

.bor_right_none {
  border-right: none !important;
}

.bor_bottom_none {
  border-bottom: none !important;
}

.bor_left_none {
  border-left: none !important;
}

:root {
  --bs-blue: #518CED;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #FD64A5;
  --bs-red: #ED5555;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #34C759;
  --bs-teal: #2ECC87;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #518CED;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #ED5555;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, .15), hsla(0, 0%, 100%, 0))
;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

img,
svg {
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
  background-color: #e9ecef !important;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

[hidden] {
  display: none !important;
}

.form-label {
  margin-bottom: .5rem;
}

.form-text {
  margin-top: .25rem;
  font-size: .875em;
  color: #6c757d;
}

.dataTable-input,
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input,
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[type=file],
[type=file].dataTable-input {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]),
[type=file].dataTable-input:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.dataTable-input:focus,
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dataTable-input::-webkit-date-and-time-value,
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.dataTable-input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-moz-placeholder, .form-control::-moz-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-ms-input-placeholder, .form-control::-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::placeholder,
.form-control::placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:disabled,
.form-control:disabled,
input:disabled,
.form-control[readonly],
[readonly].dataTable-input {
  background-color: #e9ecef !important;
  opacity: 1;
}

.dataTable-input::file-selector-button,
.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input::file-selector-button,
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button,
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.dataTable-input::-webkit-file-upload-button,
.form-control::-webkit-file-upload-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input::-webkit-file-upload-button,
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.dataTable-selector,
.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(.75rem - 3px);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #5F5F5F;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-selector,
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-selector:focus,
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]),
[multiple].dataTable-selector,
[size].dataTable-selector:not([size="1"]) {
  padding-right: .75rem;
  background-image: none;
}

.dataTable-selector:disabled,
.form-select:disabled {
  background-color: #e9ecef;
}

.dataTable-selector:-moz-focusring,
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: .1em;
  margin-right: 10px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: .25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .5;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  -webkit-transition: background-position .15s ease-in-out;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2386b7fe'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .dataTable-input,
.input-group > .dataTable-selector,
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .dataTable-input:focus,
.input-group > .dataTable-selector:focus,
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 16px;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media all and (max-width: 1340px) {
  .btn {
    font-size: 14px;
  }
}

a.btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  opacity: 0.8;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65;
}

.btn-primary {
  color: #fff;
  background-color: #518CED;
  border-color: #518CED;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #518CED;
  border-color: #518CED;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #518CED;
  border-color: #518CED;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #5F5F5F;
  border-color: #5F5F5F;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.fade {
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 5;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: .125rem;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-open .modal::after {
  position: absolute;
  content: '';
  background: rgba(95, 95, 95, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.modal.fade.show {
  opacity: 1;
  display: block;
  z-index: 999;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  max-width: 94%;
  max-height: 94vh;
  border-radius: 8px;
  overflow: hidden;
  margin: 3vh auto;
  z-index: 99;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
          transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
  min-height: calc(100% - (1.75rem * 2));
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
  background: #fff;
  border-radius: 8px;
}

.modal-content .blockinput {
  margin: 20px 0 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modal-content .blockinput input {
  height: 40px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #5F5F5F;
}

.modal-header {
  padding: 15px;
}

.modal-body {
  padding: 15px;
  overflow-y: auto;
}

.modal-footer {
  background: #fff;
  padding: 15px;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.modal-title {
  margin-bottom: 0;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal .close {
  background: #99A3B1;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.modal .modal-header {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modal .filter {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal .filter .form-control {
  border-width: 1px;
  font-size: 14px;
}

.modal .filter .form-control.searchClinic {
  min-height: 40px;
  min-width: 300px;
}

.modal .filter .txtresult {
  margin-left: auto;
  margin-right: 0;
}

.modal .modal-footer .issued {
  margin-left: auto;
  margin-right: 0;
}

.modal .modal-footer .btn:disabled {
  background: #DCDFE6;
  border-color: #DCDFE6;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #eee #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 10px;
  border: 3px solid #fff;
}

body {
  font-family: "Hiragino Sans","Helvetica Neue","Helvetica","Hiragino Kaku Gothic ProN","Arial","Yu Gothic","Meiryo",sans-serif !important;
  color: #5F5F5F;
  font-size: 16px;
}

body p {
  font-size: 16px;
  line-height: 1.8;
  color: #5F5F5F;
  font-family: "Hiragino Sans","Helvetica Neue","Helvetica","Hiragino Kaku Gothic ProN","Arial","Yu Gothic","Meiryo",sans-serif !important;
}

body a {
  color: #5F5F5F;
  line-height: normal;
  font-family: "Hiragino Sans","Helvetica Neue","Helvetica","Hiragino Kaku Gothic ProN","Arial","Yu Gothic","Meiryo",sans-serif !important;
}

body img, body iframe {
  max-width: 100%;
  line-height: 0;
}

body .img {
  overflow: hidden;
  line-height: 0;
}

body .img img {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition-duration: 4s;
          transition-duration: 4s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: transform, -webkit-transform;
}

body .container {
  padding: 0;
}

.dispc, .breakpc {
  display: block;
}

@media all and (max-width: 1180px) {
  .dispc, .breakpc {
    display: none;
  }
}

.dissp, .breaksp {
  display: block;
}

@media all and (min-width: 1181px) {
  .dissp, .breaksp {
    display: none;
  }
}

.dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.dflex_start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.dflex_end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.dflex_space {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.dflex_center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.dflex-wrap {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.dflex-nowrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.align_start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.align_end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.align_center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@page {
  size: A4;
  margin: 15px 0;
}

@page {
  @top-left {
    content: -moz-element(header);
    content: element(header);
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media screen, print {
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tbody {
    display: table-footer-group;
  }
  .header {
    display: block;
    position: running(header);
  }
  .block {
    margin-bottom: 30px;
  }
  .printpage {
    padding: 15px;
  }
  .printpage .head h2 {
    font-size: 22px;
    font-weight: bold;
  }
  .printpage .head p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .printpage .tbstyleNormal table {
    table-layout: auto;
    font-size: 16px;
  }
  .printpage .tbstyleNormal table thead {
    background: #EFEFEF;
    print-color-adjust: exact;
  }
  .printpage .tbstyleNormal table th {
    font-weight: bold;
    padding: 15px 10px;
  }
  .printpage .tbstyleNormal table td {
    font-weight: bold;
    padding: 15px 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
  }
  .printpage .tbstyleNormal table th:first-child, .printpage .tbstyleNormal table td:first-child {
    min-width: 65px;
  }
  .printpage .tbstyleNormal table th:nth-child(4), .printpage .tbstyleNormal table td:nth-child(4) {
    text-align: right;
    min-width: 80px;
  }
  .printpage .tbstyleNormal table th:last-child, .printpage .tbstyleNormal table td:last-child {
    text-align: right;
    width: 70px;
    min-width: 70px;
  }
  .printpage .tbstyleNormal table .row {
    margin-bottom: 5px;
    min-height: 26px;
  }
  .printpage .tbstyleNormal table .row.total {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .printpage .tbstyleNormal table label {
    border: 1px solid #000;
    padding: 1px 5px;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    margin-right: 5px;
  }
  .printpage_barcode, .printpage_qrcode {
    padding: 10px;
    max-width: 430px;
    margin: 0 auto;
  }
  .printpage_barcode .numBar, .printpage_qrcode .numBar {
    line-height: 1;
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    margin-top: 3px;
  }
  h2.titblock {
    font-size: 32px;
    font-weight: normal;
    line-height: 1.4;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .block_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .block_head-barcode {
    max-width: 80px;
    text-align: left;
  }
  .block_tit {
    margin: 8px 0 10px;
  }
  .block_tit h1 {
    font-size: 32px;
    line-height: 1.4;
  }
  .block_barcode {
    text-align: center;
  }
  .block_barcode img, .block_barcode svg {
    max-width: 280px;
    height: 32px;
    width: 280px;
  }
  .block_QRcode {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .block_QRcode dl:first-child {
    margin-right: 50px;
  }
  .block_QRcode dd {
    padding-left: 10px;
  }
  .block_QRcode dd span {
    display: block;
    font-size: 10px;
  }
  .block_QRcode dd span.numBar {
    text-align: left;
    font-size: 10px;
  }
  .block_info {
    margin: 12px 0 17px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #8f8686;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .block_info dl {
    padding: 12px 5px;
    -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
            align-self: stretch;
  }
  .block_info dl:last-child {
    min-width: 58%;
    word-break: keep-all;
  }
  .block_info dl dt {
    font-size: 12px;
  }
  .block_info dl dd {
    font-size: 16px;
    min-height: 30px;
    line-height: 30px;
  }
  .block_info dl dd .date {
    font-size: 29px;
    font-weight: 300;
    line-height: 1;
  }
  .block_info dl dd .date small {
    font-size: 16px;
  }
  .block_infoDetails .item {
    border-radius: 6px;
    border: 1px solid #CDD5E5;
    padding: 10px 15px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-bottom: 10px;
    overflow: hidden;
  }
  .block_infoDetails .item .colMonth {
    -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
            align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: -15px !important;
    margin-top: -10px;
    margin-bottom: -10px;
    min-height: calc(100% + 20px);
  }
  .block_infoDetails .item .colMonth p {
    color: #fff;
  }
  .block_infoDetails .item:last-child {
    margin-bottom: 0;
  }
  .block_infoDetails .item .col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    max-width: 20%;
    margin-left: 20px;
  }
  .block_infoDetails .item .col:first-child {
    margin-left: 0;
  }
  .block_infoDetails .item p {
    font-size: 12px;
  }
  .block_infoDetails .item p.num {
    font-size: 22px;
    line-height: 1.2;
    font-family: Arial, Helvetica, sans-serif !important;
    text-align: right;
  }
  .block_infoDetails .item p.clgray {
    text-align: right;
  }
  .block_table table {
    table-layout: auto;
    width: 100%;
    border-bottom: 1px solid #707070;
    margin-bottom: 15px;
  }
  .block_table thead {
    border-bottom: 1px solid #707070;
  }
  .block_table thead th {
    font-size: 12px;
  }
  .block_table thead th:first-child {
    width: 200px;
  }
  .block_table th, .block_table td {
    padding: 5px;
    text-align: center;
  }
  .block_table th:first-child, .block_table td:first-child {
    text-align: left;
  }
  .block_table .barcode {
    max-width: 94px;
  }
  .block_table .barcode svg, .block_table .barcode img {
    max-width: 100%;
    max-height: 20px;
  }
  .block_table td {
    font-size: 14px;
    vertical-align: middle;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .block_table td p.note {
    font-size: 10px;
  }
  .block_table .technics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .block_table .technics_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .block_table .technics_list li {
    font-size: 14px;
    font-weight: bold;
    min-height: 22px;
    min-width: 16px;
    text-align: center;
    line-height: 22px;
    position: relative;
    padding: 0 5px;
  }
  .block_table .technics_list li:first-child {
    margin-right: 3px;
  }
  .block_table .technics_list li::after {
    position: absolute;
    content: '';
    background: #000;
    width: 100%;
    height: 1px;
  }
  .block_table .technics_list li::before {
    position: absolute;
    content: '';
    background: #000;
    height: 100%;
    width: 1px;
  }
  .block_table .technics_list li.lineTop::after {
    left: 0;
    top: 0;
  }
  .block_table .technics_list li.lineBottom::after {
    left: 0;
    bottom: 0;
  }
  .block_table .technics_list li.lineLeft::before {
    left: 0;
    top: 0;
  }
  .block_table .technics_list li.lineRight::before {
    right: 0;
    top: 0;
  }
  .block_table .technics .name {
    font-size: 14px;
  }
  .block_table .tbstyle3 thead {
    border-bottom: 0;
  }
  .block_table .tbstyle3 th, .block_table .tbstyle3 td {
    padding-left: 10px;
    padding-right: 10px;
  }
  .block_table .tbstyle3 th:first-child, .block_table .tbstyle3 td:first-child {
    padding-left: 10px;
    width: auto;
  }
  .block_table .tbstyle3 th {
    font-size: 14px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .block_table .tbstyle3 p.clgray {
    font-size: 13px;
    color: #99A3B1;
    font-weight: bold;
  }
  .block_table .tbstyle3 p.num {
    font-size: 26px;
    line-height: 1.2;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .block_table .tbstyle3 p.num small {
    font-size: 14px;
  }
  .block_table .tbstyle3 .name h2 {
    font-size: 20px;
  }
  .block_table .tbstyle3 .name .num {
    font-size: 14px;
    color: #99A3B1;
  }
  .block_table .tbstyle3 .name .num label {
    background: #99A3B1;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    height: 20px;
    padding: 0 10px;
    display: inline-block;
    margin-right: 5px;
  }
  .block_table .tbstyle3 .alphabet {
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .block_textInfo .titblock {
    margin-top: 0;
  }
  .block_textInfo p {
    font-size: 24px;
    line-height: 1.4;
  }
  .block_chart {
    margin: 25px 0;
    border: 1px solid #CDD5E5;
    padding: 15px;
  }
  .block_chart canvas {
    min-height: 550px;
    max-height: 580px;
  }
  .block_subinfo {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 20px;
  }
  .block_subinfo .confirmBox {
    width: 24%;
  }
  .block_subinfo .confirmBox .signature {
    width: 56px;
    height: 64px;
    background: url(../images/confirmBox.svg) no-repeat;
    background-size: 100%;
  }
  .block_subinfo .subinfo {
    margin-left: 0;
    margin-right: auto;
    max-width: 76%;
  }
  .block_subinfo table {
    width: 100%;
  }
  .block_subinfo table td {
    font-size: 12px;
    text-align: right;
    padding: 8px 5px;
  }
  .block_implement dl {
    border-top: 1px solid #CDD5E5;
    padding: 15px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    position: relative;
  }
  .block_implement dl:last-child {
    border-bottom: 1px solid #CDD5E5;
  }
  .block_implement dl dt label {
    background: #fff;
    padding: 3px 7px;
    color: #5E6D8E;
    display: inline-block;
    border: 2px solid #5E6D8E;
    line-height: 1.2;
    font-size: 12px;
    font-weight: bold;
  }
  .block_implement dl dt p {
    font-size: 18px;
    color: #5E6D8E;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 5px;
  }
  .block_implement dl dt .alphabet {
    margin: 0;
    position: absolute;
    right: 0;
    top: 18px;
    width: 44px;
    height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .block_implement dl dd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 80px;
  }
  .block_implement dl dd .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 50px;
  }
  .block_implement dl dd .row:first-child {
    margin-left: 0;
  }
  .block_implement dl dd .item {
    margin-left: 50px;
    min-width: 75px;
  }
  .block_implement dl dd .item:first-child {
    margin-left: 0;
  }
  .block_implement dl dd .item p {
    font-size: 12px;
    font-weight: bold;
  }
  .block_implement dl dd .item .num {
    font-size: 22px;
    font-weight: normal;
    line-height: 1.2;
    text-align: right;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .block_implement dl dd .item .num small {
    font-size: 12px;
    font-family: "Hiragino Sans","Helvetica Neue","Helvetica","Hiragino Kaku Gothic ProN","Arial","Yu Gothic","Meiryo",sans-serif !important;
  }
  .block_implement dl dd .item .clgray {
    color: #99A3B1;
    margin-left: 20px;
  }
  .block_implement dl.total dt, .block_implement dl.total dd {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    margin: 0;
  }
  .block_implement dl.total dd {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .printpage_barcode .block_table table td:last-child {
    min-width: 70px;
  }
  .printpage_barcode .block_table table td:last-child .barcode {
    max-width: 60px;
  }
  .printpage_barcode .block_table table td:nth-child(2) {
    min-width: 105px;
  }
  .printpage_barcode .block_table table td:nth-child(3) {
    padding-top: 0;
    min-width: 35px;
  }
  .printpage_qrcode .block_table table td:last-child {
    width: 40px;
  }
  .printpage_qrcode .block_table table td:last-child .barcode {
    max-width: 40px;
  }
  .printpage_qrcode .block_table table td:nth-child(2) {
    width: 40px;
  }
  .printpage_indiPerform .block_tit {
    margin-top: 0;
  }
}
