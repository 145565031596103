.videoRef {
  width: 100%;
  height: 100%;
  object-fit: cover;
  video {
    width: 460px;
    height: 100%;
  }
}

.scanner-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 460px;
  height: 460px;
  margin:0 auto;
}
.scannedCode {
  .scanner-video {
    &:before {
      display: none;
    }
  }
}

.scanner-video {
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    width: calc(100% - 160px);
    height: 5px;
    background-color: #4EDB60;
    animation: scan 2s infinite linear;
  }  
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position:absolute;
    background: url("../../images/scan.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    z-index: 10;
    @include centerboth();
  }
}
.cameraSwitch {
  position:absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: 10px;
  z-index: 999999;
  cursor: pointer;
  &:hover { 
    opacity: 0.8;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.false {
    display: none;
  }  
}
@keyframes scan {
  from {
    top: 50px;
  }
  to {
    top: calc(100% - 100px);
  }
}