@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break:nth-child(2n) {
    break-after: page;
    page-break-before: always;
  }

}

@page {
  size: auto;
  zoom: 50%;
  margin: 20mm;
}

#process {
  position: relative;
}

#mainPrintReceipt {
  position: absolute;
  left: -430px;

  .main-print {
    border: 1px solid #a7a4a4;
    padding: 20px;
  }
  .printpage_barcode .block_table table td {
    padding-top: 10px;
  }

  .block_table .technics {
    align-items: center;

    .break-label {
      background-color: #000;
      color: #fff;
      padding: 1px 3px;
      margin-right: 3px;
    }
  }
}


.printpage  {
  .block_table .technics_list li {
    margin-right: 3px;
  }
}