.react-datepicker__day--highlighted-custom-1 {
    color: #f65a5a !important;
    border-radius: 100%;
    background: #ffd8d8;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}